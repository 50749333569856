var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:(_vm.canCancel && _vm.order.comment) || (_vm.canCancel && !_vm.order.comment)
        ? 'order-card-wrapper round-border'
        : 'order-card-wrapper'},[_c('InsideCardHeader',{attrs:{"order":_vm.order,"isHotel":_vm.isHotel}}),_c('InsideStatusBar',{attrs:{"order":_vm.order,"index":0}}),_c('CardList',{attrs:{"list":_vm.order.list.filter(function (item) { return item.append_index == null; })}}),(_vm.shouldShowAppends)?_c('div',{staticClass:"appends"},_vm._l((_vm.acceptedAppends),function(append,index){return _c('div',{key:append._id,staticClass:"append"},[_c('div',{class:_vm.getAppendClass(append)},[_c('div',{directives:[{name:"thematic",rawName:"v-thematic"}],staticClass:"border-line"},[_vm._v("\n            — — — — — — — — — — — — — — — — —\n          ")]),_c('div',{directives:[{name:"thematic",rawName:"v-thematic"}],staticClass:"dot"}),_c('div',{directives:[{name:"thematic",rawName:"v-thematic"}],staticClass:"dot right"}),_c('InsideStatusBar',{attrs:{"order":append,"index":index + 1}})],1),_c('CardList',{attrs:{"list":append.list}})],1)}),0):_vm._e(),_c('div',{class:[
        _vm.canCancel && !_vm.order.comment ? 'total-block' : 'total-block',
        _vm.order.comment ? 'total-block no-border' : 'total-block',
        _vm.order.status === 'cancelled' ? 'no-border' : '' ]},[_c('h3',{staticClass:"total"},[_vm._v(_vm._s(_vm.$t("list.total")))]),_c('span',{staticClass:"amount"},[_vm._v(_vm._s(_vm._f("formatMoney")(_vm.total))+_vm._s(_vm.currency.symbol))])]),(_vm.order.comment)?_c('div',{directives:[{name:"thematic",rawName:"v-thematic"}],class:{
        'order-comment': true,
        'no-radius': _vm.order.status == 'cancelled' && _vm.order.reason,
      }},[_c('h6',{staticClass:"color-grey"},[_vm._v(_vm._s(_vm.$t("cart.comment")))]),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.order.comment))])]):_vm._e(),(_vm.canCancel)?_c('div',{staticClass:"card-action"},[_c('LongButton',{staticClass:"btn-cancel",attrs:{"variant":"danger"},on:{"click":_vm.onCancel}},[_vm._v(_vm._s(_vm.$t("actions.cancel")))])],1):_vm._e(),(_vm.order.status == 'cancelled' && _vm.order.reason)?_c('div',{directives:[{name:"thematic",rawName:"v-thematic"}],class:{
        'order-comment': true,
      }},[_c('h6',{staticClass:"color-grey"},[_vm._v(_vm._s(_vm.$t("card.cancel_reason")))]),_c('p',{staticClass:"color-red mb-0"},[_vm._v(_vm._s(_vm.order.reason))])]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }