<template>
  <div>
    <div class="hide-content"></div>
    <div class="deleted-dish-wrapper">
    <div class="deleted-dish-modal">
      
      
      <h3>{{ $t('deleted_dish.sorry') }}</h3>
      <p>
        {{ $t('deleted_dish.description') }} {{ reason }}
      </p>
  
      
      <div class="d-flex align-items-center" v-for="item in list" :key="item">
        <img src="/icons/red-warning.svg" class="alarm-icon" />
        <span class="deleted d-inline-block">
            {{ item.name }} <small v-if="item.variant">{{ item.variant }}</small> x {{ item.quantity }}
        </span>
      </div>
    </div>
    <LongButton class="w-100 round-border" @click="emitConfirm">{{ $t('deleted_dish.order_else') }}</LongButton>
    </div>
  </div>
</template>

<style scoped>
.hide-content {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 2;
  overflow: hidden;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.4);
  opacity: 1;
  top: 0;
  left: 0;
}
.alarm-icon {
  height: 3vh;
  width: auto;
}

span.reason  {
  display:list-item;
  margin: 2vh 10vw 0 4vw;
  color: #EDEDED;

}

.round-border {
  border-radius: 1.5vh;
}

.deleted-dish-modal p {
  font-size: 4.5vw;
  text-align: center;
  margin-bottom: 0;
  padding: 3vh 3vw 0 0;
  color: #A4A3A1;
}

.deleted {
  color: #E2223B;
  text-decoration: line-through;
  font-weight: 500;
  padding: 2.3vh 0 0 2vh;
  font-size: 5.5vw;
  margin-bottom:0;
}

h3 {
  font-size: 5.5vw;
  margin-bottom: 0;
  text-align: center;
  color: #EDEDED;
 
}

.deleted-dish-modal {
  background: #1D1D1B;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2.3vh;
  width: 90vw;
  padding: 2.5vh 5.4vw 3.7vh;
}

.deleted-dish-wrapper {
  z-index: 3;
  margin: 0 auto;
  position: absolute;
  width: 90%;
  left: 50%;
  right: 0;
  top:50%;
 transform: translate(-50%, -50%);
}

img.close{
      height: 4.2vh;
    width: auto;
    opacity: 1;
    position: relative;
    float: right;
}
</style>

<script>
export default {
  props: {
    list: Array,
    reason: String
  },
  methods: {
      emitConfirm() {
          this.$emit('confirm');
      },
      emitClose() {
          this.$emit('close');
      }
  }
};
</script>