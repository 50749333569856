<template>
  <div>
    <div class="hide-content"></div>
    <div v-thematic class="modal-container">
      <div class="wait">
        <img src="/icons/wait.svg" alt="waiter" />
        <p class="color-grey" v-if="!online">
          {{ $t("superman.paid_cash_card") }}
        </p>
        <p class="color-grey" v-if="online">{{ $t("superman.paid_online") }}</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.modal-container {
  position: fixed;
  width: 100%;
  background: #ededed;
  height: 100vh;
  margin: 0 auto;
  left: 0;
  overflow: hidden;
  right: 0;
  top: 7vh;
  z-index: 12;
}

.modal-container.dark {
  background: black;
}

.hide-content {
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5px);
  top: 0;
  left: 0;
  z-index: 2;
}

p {
  padding: 4.5vh 15vw 0;
  margin-bottom: 0;
  line-height: 2.9vh;
}

.wait {
   padding:0 5.3vw 4.5vh;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  position: relative;
  top:25%;
}

.wait img {
  height: 25vh;
  width: auto;
  margin-left: 1.1vh;
}

img.close {
  width: auto;
  height: 4.2vh;
  opacity: 1;
  margin-top: 0;
  right: 5.5vw;
  position: absolute;
}
</style>

<script>
export default {
  props: {
    online: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
  },
  mounted() {
    setTimeout(this.emitClose, 2500);
  },
};
</script>