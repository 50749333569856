<template>
  <div>
    <div
      :class="
        (canCancel && order.comment) || (canCancel && !order.comment)
          ? 'order-card-wrapper round-border'
          : 'order-card-wrapper'
      "
    >
      <InsideCardHeader :order="order" :isHotel="isHotel" />
      <InsideStatusBar :order="order" :index="0" />
      <!-- <CardHeader :order="order" :number="1" :waitingTime="waitingTime" /> -->
      <CardList :list="order.list.filter(item => item.append_index == null)" />

      <div class="appends" v-if="shouldShowAppends">
        <div
          class="append"
          v-for="(append, index) in acceptedAppends"
          :key="append._id"
        >
          <div :class="getAppendClass(append)">
            <!-- <span
              class="append-total text-center d-block"
              v-if="index == 0"
            >{{ $t('list.total') }} {{ computeAppendTotal(order) }}{{currency.symbol}}</span>
            <span
              class="text-center d-block"
              v-if="index > 0"
            >{{ $t('list.total')}} {{ computeAppendTotal(order.appends[index-1])}}{{currency.symbol}}</span> -->
            <div class="border-line" v-thematic>
              — — — — — — — — — — — — — — — — —
            </div>
            <div class="dot" v-thematic></div>
            <div class="dot right" v-thematic></div>

            <InsideStatusBar :order="append" :index="index + 1" />
            <!-- <h5>{{ $t('card.append') }}</h5>
            <span class="append-status">{{ getAppendStatus(append) }}</span>
            <span
              class="append-status"
              v-if="append.status == 'serving'"
            >{{ getAppendWaitingTime(append) }}</span> -->
          </div>

          <CardList :list="append.list" />
        </div>
      </div>

      <div
        :class="[
          canCancel && !order.comment ? 'total-block' : 'total-block',
          order.comment ? 'total-block no-border' : 'total-block',
          order.status === 'cancelled' ? 'no-border' : '',
        ]"
      >
        <h3 class="total">{{ $t("list.total") }}</h3>
        <span class="amount">{{ total | formatMoney }}{{ currency.symbol }}</span>
      </div>

      <div
        :class="{
          'order-comment': true,
          'no-radius': order.status == 'cancelled' && order.reason,
        }"
        v-thematic
        v-if="order.comment"
      >
        <h6 class="color-grey">{{ $t("cart.comment") }}</h6>
        <p class="mb-0">{{ order.comment }}</p>
      </div>

      <div class="card-action" v-if="canCancel">
        <LongButton variant="danger" class="btn-cancel" @click="onCancel">{{
          $t("actions.cancel")
        }}</LongButton>
      </div>

      <!-- <div class="promocode-container" v-if="order.status == 'unpaid'">
        <PromocodeInput
          :ignoreDark="true"
          :mode="'inside'"
          :cafe_id="cafe._id"
          v-model="promocode"
        />
      </div> -->

      <!-- <CardTips
        :cafe="cafe"
        :order="order"
        @tip="onTipSelect"
        @pay="propogatePayEvent"
        v-if="order.status == 'unpaid'"
      /> -->

      <div
        :class="{
          'order-comment': true,
        }"
        v-thematic
        v-if="order.status == 'cancelled' && order.reason"
      >
        <h6 class="color-grey">{{ $t("card.cancel_reason") }}</h6>
        <p class="color-red mb-0">{{ order.reason }}</p>
      </div>

      <!-- <div class="cancel-reason" v-thematic v-if="order.status === 'cancelled'">
        <span v-if="order.reason">{{ order.reason }}</span>
      </div> -->
    </div>
    <!-- <p
      v-if="canCancel"
      class="dont-close color-grey"
    >{{ $t('card.dont_close') }}
      <br>
      {{ $t('card.play_game') }}
    </p> -->
  </div>
</template>

<style scoped>
.order-card-wrapper {
  width: 90%;
  margin: 2.5vh auto 2.5vh;
  background: #ededed;
  display: block;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2.3vh;
}

.append-total {
  line-height: 0.8;
  margin-bottom: 0.9vh;
}

.round-border {
  border-radius: 4vh !important;
}

.promocode-container {
  /* padding: 2.2vh; */
  border-bottom: 1px solid #908f8d;
  /* border-top: 1px solid #908f8d; */
  margin-top: 0;

  padding: 0 2.2vh 2.2vh 2.2vh;
  width: 100%;
}

.dont-close {
  font-weight: 500;
  font-size: 5vw;
  padding: 3vh 16vw 0;
  line-height: 6vw;
  text-align: center;
}

.total-block {
  line-height: 0.8;
  padding: 2.5vh 2.5vh 3.7vh;
}

.total-block.no-border {
  border-radius: 0;
}

.total {
  font-weight: bold;
  display: inline-block;
  margin: 0 !important;
  font-size: 3vh;
  text-align: initial;
  padding-top: 0.9vh;
  line-height: 0.8;
}

span.amount {
  float: right;
  margin-top: 0vh;
  line-height: 0.8;
  font-size: 4.4vh;
  font-weight: 500;
}

.dot {
  height: 15px;
  width: 25px;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;

  transform: rotate(-270deg);
  margin: 0.2vh 0 0 -0.7vh;

  background: #ededed;
  position: inherit;
}

.dot.dark {
  transform: rotate(-270deg);
  height: 15px;
  width: 25px;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  background: #000;
}

.no-radius {
  border-radius: 0 !important;
}

.dot.right {
  right: 0;
  position: absolute;
  transform: rotate(-90deg);
  margin: -1.7vh 1.7vh 0 0;
}

.dot.right.dark {
  transform: rotate(-90deg);
}

.append-stripe {
  display: block;
  margin-top: 0;
  padding: 1.8vh 0 0;
  border-radius: 0;
  width: 100%;
}

.append-status {
  font-size: 3vw;
  padding-top: 0.5vh;
  text-align: center;
  display: block;
}

.border-line {
  width: 100%;
  height: 1.5vh;
  font-weight: 100;
  font-size: 3.8vw;
  padding-left: 1vh;
  margin-bottom: -1.4vh;
  color: #ededed;
}

.border-line.dark {
  color: #000;
}

@media screen and (max-width: 330px) {
  .dot.right {
    margin-top: -3.5vh;
  }
}

.append-stripe h6 {
  font-size: 4vw;
  text-align: center;
}

.append-stripe h5 {
  font-size: 4.5vw;
  text-align: center;
  margin: 0;
  display: block;
}

.append-waiting {
  background-color: #1d1d1b;
}

.append-serving {
  background-color: #1d1d1b;
}

.append-cancelled {
  background-color: #e2223b;
}

.card-action {
  padding: 0 0 2.5vh 0;
}

.btn-cancel {
  line-height: 0.8;
  background: #ededed;
  border: 1px solid #e2223b;
  border-radius: 5vh;
  box-shadow: none;
  padding: 2.2vh 0;
  font-size: 2.2vh;
  width: 90%;
  margin: 0 auto;
  font-weight: 500;
  color: #e2223b;
}

.cancel-reason {
  color: #e2223b;
  margin-top: -1px;
  padding: 0 5.4vw 2.4vh;
}

.cancel-reason.dark {
  background: #ededed;
  border-radius: 0 0 2vh 2vh;
}

.order-comment {
  padding: 0 5.4vw 2.5vh;
  margin: -1px 0;
}

.order-comment p {
  padding: 1.3vh 4vw 0;
  margin-bottom: 0;
  font-size: 4.5vw;
}

.order-comment h6 {
  font-weight: 500;
  font-size: 5vw;
  margin-bottom: 0;
}
</style>

<script>
import InsideStatusBar from "@/components/card/InsideStatusBar.vue";
import InsideCardHeader from "@/components/card/InsideCardHeader.vue";
import CardHeader from "@/components/card/CardHeader.vue";
import CardList from "@/components/card/CardList.vue";
import CardTips from "@/components/card/CardTips.vue";
import PromocodeInput from "@/components/order-details/PromocodeInput.vue";

import OrderHelpers from "@/services/order/helpers.js";

import moment from "moment";

export default {
  data() {
    return {
      cancelSeconds: 0,
      cancelTimerId: null,
      cancellableAppends: [],

      // promocode: null,
    };
  },
  props: {
    order: Object,
    cafe: Object,
  },
  watch: {
    // promocode() {
    //   this.$emit('promocode', this.promocode);
    // }
  },
  methods: {
    propogatePayEvent(arg) {
      this.$emit("pay", arg, this.promocode);
    },
    onTipSelect(arg) {
      this.$emit("tip", arg);
    },
    getAppendClass(append) {
      return ["append-stripe color-white", `append-${append.status}`];
    },
    getAppendStatus(append) {
      const raw = append.status;

      if (raw === "waiting") {
        return this.$t("card.status_waiting");
      }

      if (raw === "serving") {
        const mins = Math.abs(
          moment().diff(
            moment(append.processed_at || this.order.date),
            "minutes"
          )
        );

        if (mins < 1) {
          return this.$t("card.status_serving_recent");
        } else {
          return this.$t("card.status_serving", {
            mins,
          });
        }
      }

      if (raw === "cancelled") {
        return this.$t("card.status_cancelled");
      }
    },
    getAppendWaitingTime(append) {
      const wt = this.computeWaitingTime(append.list);

      if (wt <= 0) return "";

      return `${this.$t("card.waiting_time")} ${wt} ${this.$t(
        "units.minutes"
      )}`;
    },
    computeWaitingTime(list) {
      return list.reduce((acc, curr, idx) => {
        if (curr.waiting_time) {
          if (acc == 0) {
            return curr.waiting_time;
          } else {
            return curr.waiting_time < acc ? curr.waiting_time : acc;
          }
        } else {
          return acc;
        }
      }, 0);
    },
    onCancel() {
      this.$emit("cancel");
    },
    mergeAppendsVisuallyIfNeeded() {
      if (this.order.appends)
        this.cancellableAppends = this.order.appends.filter((append) => {
          const now = moment();
          const then = moment(append.date);

          return now.diff(then, "seconds") > 30;
        });
    },
    tickCancelTimer() {
      this.cancelSeconds--;

      if (this.cancelSeconds <= 0) {
        clearInterval(this.cancelTimerId);
      }

      this.$emit("timer", this.cancelSeconds);
    },
    startCancelTimer() {
      const diff = moment(this.order.date)
        .add(30, "seconds")
        .diff(moment(), "seconds");

      if (diff > 0) {
        this.cancelSeconds = diff;
        this.cancelTimerId = setInterval(this.tickCancelTimer, 1000);
        setInterval(this.mergeAppendsVisuallyIfNeeded, 1000);
      }
    },
    computeAppendTotal(ap) {
      return OrderHelpers.computeTotal(ap.list);
    },
  },
  mounted() {
    this.startCancelTimer();
  },
  computed: {
    total() {
      return this.order.total;
    },
    waitingTime() {
      return this.computeWaitingTime(this.order.list);
    },
    canCancel() {
      return this.order.status === "waiting" && this.cancelSeconds > 0;
    },
    shouldShowAppends() {
      return (
        this.order.appends &&
        this.order.appends.length &&
        ["waiting", "cancelled", "serving"].includes(this.order.status)
      );
    },
    acceptedAppends() {
      if (!this.order.appends) return [];

      return this.order.appends.filter((ap) => {
        return ap.status == "serving";
      });
    },
    currency() {
      return this.$store.state.currency.current;
    },
    isHotel() {
      return false;
    },
  },
  components: {
    InsideCardHeader,
    InsideStatusBar,
    CardHeader,
    CardList,
    CardTips,
    PromocodeInput,
  },
};
</script>