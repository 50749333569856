import io from 'socket.io-client';

let Socket = null;
let Callbacks = {};

function connect(_Callbacks) {
    if (window.location.hostname == 'localhost') {
        Socket = io('localhost:3000/order');
    } else {
        Socket = io('/order');
    }

    Callbacks = _Callbacks;

    setupEvents();
}

function listenOrder(order_id) {
    Socket.emit('listen', order_id);
}

function setupEvents() {
    Socket.on('connect', Callbacks.onConnect);

    Socket.on('update', Callbacks.onUpdate);

    Socket.on('switch', Callbacks.onSwitch);

    Socket.on('bill', Callbacks.onBill);

    Socket.on('continue_payment', Callbacks.onContinuePayment);
}

function sync(order_id, target) {
    Socket.emit('sync', order_id, target);
}

function cancelOrder(order_id, reason) {
    Socket.emit('cancel', order_id, reason);
}

function cancelAppend(order_id, append_index) {
    Socket.emit('cancel_append', order_id, append_index);
}

function requestPayment(order_id, method, tip) {
    Socket.emit('pay', order_id, method, tip || 0);
}

function requestServiceCall(order_id, service) {
    Socket.emit('call', order_id, service);
}



export default {
    listenOrder,
    cancelOrder,
    cancelAppend,
    connect,
    sync,
    requestPayment,
    requestServiceCall
}