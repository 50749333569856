<template>
  <div class="roulette-wrapper">
    <h3 class="color-white">{{ $t("roulette.your_prize") }}</h3>

    <div class="roulette-pointer">
      <img src="../../assets/roulette-arrow.png" alt />
    </div>
    <div class="roulette-container">
      <div class="roulette-window" ref="rouletteWindow">
        <div
          class="roulette-item"
          v-for="(prize, index) in allPrizes"
          :key="`prize-${index}`"
        >
          <div v-if="prize">
            <img :src="prize.image" />

            <div class="prize-info">
              <b class="color-white">{{ prize.name }}</b>
              <span class="float-right color-white"
                >{{ getPrizeCost(prize) }} {{ currency.symbol }}</span
              >
            </div>
          </div>
          <div v-if="!prize">
            <div class="no-prize">
              <img src="/icons/no-prize.jpg" alt="No Prize :(" />
            </div>
            <div class="prize-info">
              <b class="color-white">{{ $t("roulette.no_prize") }}</b>
            </div>
          </div>

          <!-- <b>{{ prize.price }}</b> -->
        </div>
      </div>
    </div>

    <p class="color-grey" v-html="$t('roulette.will_be_added')"></p>

    <LongButton @click="confirmRoll" class="roll-btn">{{
      $t("roulette.try")
    }}</LongButton>

    <LongButton 
      v-if="!startedRolling" 
      variant="red-outline" 
      @click="$emit('close')">
      {{ $t("roulette.close") }}
    </LongButton>
  </div>
</template>

<script>
const ROLL_DURATION = 7500;
const ROLL_SPEED_INCREASE = 1;
let ROLL_ITEM_SIZE = 150;
const ROLL_FRAME_DELTA = 1000 / 60;

const ROLL_WIN_INDEX_START = 20;
const ROLL_WIN_INDEX_END = 30;

function swingEase(p) {
  return 0.5 - Math.cos(p * Math.PI) / 2;
}

export default {
  props: {
    prizes: Array,
    prize: Object,
  },
  data() {
    return {
      isRolling: false,
      startedRolling: false,

      animStart: 0,
      animPos: 0,

      win_index: 0,
      win_position: 0,

      allPrizes: [],
    };
  },
  methods: {
    rollTick() {
      const now = Date.now();
      const elapsed = now - this.animStart;
      const elapsedSeconds = elapsed / 1000;

      this.animPos = swingEase(elapsed / ROLL_DURATION) * this.win_position;

      this.$refs.rouletteWindow.style.right = `${this.animPos}px`;

      if (elapsed >= ROLL_DURATION) {
        this.finishRolling();
        return;
      }

      if (this.isRolling) {
        window.requestAnimationFrame(this.rollTick);
      }
    },
    fillPrizeItem() {
      this.win_index =
        Math.floor(
          Math.random() * (ROLL_WIN_INDEX_END - ROLL_WIN_INDEX_START + 1)
        ) + ROLL_WIN_INDEX_START;

      this.win_position =
        (this.win_index - 1) * ROLL_ITEM_SIZE + ROLL_ITEM_SIZE * 1.6;

      for (let i = 0; i < Math.floor(this.allPrizes.length * 0.3); i++) {
        const idx = Math.floor(Math.random() * this.allPrizes.length);

        this.allPrizes[idx] = null;
      }

      this.$set(this.allPrizes, this.win_index, this.prize);
    },
    finishRolling() {
      this.isRolling = false;

      let vm = this;

      setTimeout(() => {
        vm.$emit("finish");
      }, 2000);
    },
    confirmRoll() {
      this.$emit('confirm');
    },
    startRolling() {
      if (this.startedRolling) return;
      this.allPrizes = this.manyPrizes.slice();

      this.isRolling = true;
      this.startedRolling = true;
      this.animPos = 0;
      this.animStart = Date.now();

      this.fillPrizeItem();
      this.rollTick();
    },
    getPrizeCost(pr) {
      let ttl = pr.base_price || 0;

      ttl += pr.price_groups
        .filter((pg) => pg.single_select)
        .reduce((acc, it) => {
          return acc + it.items[0].price;
        }, 0);

      return ttl;
    },
  },
  mounted() {
    this.allPrizes = this.manyPrizes.slice();
    ROLL_ITEM_SIZE = Math.floor(window.screen.width * 0.4);
  },
  computed: {
    manyPrizes() {
      let result = [];

      for (let i = 0; i < ROLL_WIN_INDEX_END + 10; i++) {
        const index = Math.floor(Math.random() * this.prizes.length);
        result.push(this.prizes[index]);
      }

      return result;
    },
    currency() {
      return this.$store.state.currency.current;
    },
  },
};
</script>

<style scoped>
.roulette-container {
  overflow: hidden;
  width: 90vw;
  padding-top: 3vh;
}

.roll-btn {
  margin: 4.6vh auto 0;
  margin-bottom: 0;
}

.roulette-wrapper p {
  line-height: 6vw;
  padding-top: 2vh;
  margin-bottom: 0;
  text-align: center;
}

h3 {
  padding-top: 3vh;
  font-size: 8vw;
  margin-bottom: 0;
  text-align: center;
}

.roulette-window {
  width: 25000px;
  height: 100px;
  position: relative;
  right: 18vw;
}

.roulette-pointer img {
  height: auto;
  width: 8vw;
  margin: 0 auto;
  margin-top: 2.3vh;
  display: block;
}

.roulette-item {
  float: left;
}

.roulette-item:first-child {
  /* margin-left: -14vw; */
}

.roulette-item b {
  font-size: 4vw;
  font-weight: 500;
  margin-top: 0.7vh;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 24vw;
  display: inline-block;
  padding-left: 1.5vw;
}

.prize-info {
  margin-top: 0.8vh;
}

div span {
  font-size: 5vw;
  padding-right: 2.5vw;
}

.roulette-item img {
  width: 40vw;
  height: 12vh;
  border-radius: 2vw;
  margin: 0 1vh 0 0vw;
  display: inline-block;
}

.no-prize {
  width: 40vw;
  height: 12vh;
  margin: 0 1vh 0 0vw;
  display: inline-block;
  border-radius: 2vw;
  background: transparent;
}

.no-prize img {
  border-radius: 2vw;
}
</style>