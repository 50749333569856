<template>
  <div class="order-session">
    <div v-if="cart && cart.length > 0 && !isShowingBill">
      <OrderCart
        :cart="cart"
        :target="target"
        :disabled="busy"
        :isHotel="isHotel"
        :hideSendButton="true"
        :number="cartNumber"
        @send="onCartSend"
        @setPromocode="(p) => (sendData.promocode = p)"
        @setComment="(c) => (sendData.comment = c)"
      />

      <div
        class="inside-comment-container"
        v-if="!cafe.ui_settings.hide_comment"
      >
        <CommentField v-model="sendData.comment" />
      </div>

      <PromocodeInput
        class="inside-promocode-container"
        :cafe_id="target.cafe_id"
        mode="inside"
        v-model="sendData.promocode"
      />

      <LongButton
        variant="success-outline"
        class="offline-give-tips-btn"
        @click="showTipsModal = true"
        v-if="
          !mainOrderSent &&
          cart.length &&
          !hasTipsInCart &&
          cafe.prepayment_required
        "
      >
        + {{ $t("tips_modal.add_tips") }}
      </LongButton>

      <LongButton variant="success" class="rect-borders" @click="onCartSend">
        {{ $t("order_buttons.send") }}
      </LongButton>
    </div>

    <div
      class="empty-cart"
      v-thematic
      v-if="!mainOrderSent && (!cart || cart.length === 0)"
    >
      <img src="../assets/error-hero.svg" />
      <h3>{{ $t("cart.empty") }}</h3>
    </div>

    <div v-if="mainOrderSent && !isShowingBill">
      <div class="pending-append" v-for="(append, index) in pendingAppends">
        <CompleteOrderCard :order="append" @cancel="onAppendCancel(index)" />
      </div>

      <CompleteOrderCard
        :order="order"
        :cafe="cafe"
        @cancel="showCancelUI"
        @pay="showPayMethodUI"
        @tip="onTipSelected"
        @timer="onCancelTimerTick"
        ref="mainCard"
      />

      <div
        class="self-service-notification"
        v-thematic
        v-if="showSelfServiceNotification"
      >
        {{ $t("card.self_service") }}
      </div>

      <!-- <LongButton
        variant="success"
        class="rect-borders"
        @click="startPayment()"
        v-if="canPay"
      >
        {{ $t("actions.pay") }}
      </LongButton> -->

      <PayMethods
        :available_methods="cafe.pay_methods"
        v-if="order.status == 'unpaid'"
        :validation="paymethod_validation"
        :total="order.total"
        :showBonus="canPayByBonus"
        :bonus_balance="clientBonusBalance"
        v-model="selectedPayMethod"
      />

      <LongButton
        variant="success-outline"
        class="give-tips-btn"
        @click="showTipsModal = true"
        v-if="
          (!order || tipping_methods.cash || tipping_methods.online) &&
          !hasTipInOrder
        "
      >
        + {{ $t("tips_modal.add_tips") }}
      </LongButton>

      <div
        class="inside-splits-container"
        v-if="
          order.status == 'unpaid' &&
          order.split_payments &&
          order.split_payments.length > 1 &&
          selectedPayMethod == 'online'
        "
      >
        <SplitPaymentsList :hideComment="true" :splits="order.split_payments" />
      </div>

      <LongButton
        variant="success"
        class="rect-borders"
        @click="startPayment()"
        v-if="
          canPay || (!canSend && order.status == 'unpaid' && !order.paid_at)
        "
        >{{ $t("actions.pay") }}</LongButton
      >

      <!-- <LongButton
        class="bg-grey"
        @click="orderOtherItems"
        v-if="order.status != 'unpaid'"
      >{{ $t('card.order_else') }}</LongButton> -->

      <!-- <a
        href="https://app.qrwaiter.com.ua/download"
        target="_blank"
        v-if="order.status != 'unpaid' && !isFromApp"
      >
        <LongButton variant="dark" class="mt-20">{{
          $t("card.download_app")
        }}</LongButton>
      </a> -->

      <div
        class="snake-button"
        @click="tryToOpenSnake"
        v-if="['waiting', 'serving'].includes(order.status)"
      >
        <img src="../assets/snake.png" alt="Snake Game Logo" />
      </div>
    </div>

    <div v-if="isShowingBill">
      <OrderBill :order="order" :cafe="cafe" />

      <div v-for="sBill in splittedBills">
        <br />
        <OrderBill :order="sBill" :cafe="cafe" />
      </div>

      <LongButton
        variant="success-outline"
        @click="showTipsModal = true"
        v-if="tipping_methods.cash || tipping_methods.online"
      >
        {{ $t("tips_modal.leave_tips") }}
      </LongButton>

      <ContactIcons :cafe="cafe" class="pb-3" />

      <FeedbackButton @click="showFeedback = true" v-if="!feedbackSent" />

      <!-- <a
        href="https://app.qrwaiter.com.ua/download"
        target="_blank"
        v-if="!isFromApp"
      >
        <LongButton variant="dark">{{ $t("card.download_app") }}</LongButton>
      </a> -->

      <!-- <p v-thematic class="join-cafe">
        {{ $t('order_buttons.if1') }}
        <a href="https://qrwaiter.com.ua" target="_blank">{{ $t('order_buttons.if2') }}</a>
      </p>-->

      <!-- <img src="/icons/logo.svg" v-if="isDarkMode" class="logo" />
      <img src="../assets/logo.png" v-else class="logo" /> -->
    </div>

    <SupportIcon />

    <CafeFeedback
      :order_id="order._id"
      @cancel="closeFeedback"
      @success="onFeedbackSent"
      v-if="showFeedback"
    />

    <OrderButtons
      mode="cart"
      :amount="cart.length"
      @pay="startPayment"
      @send="onCartSend"
      :order="order"
      @switchView="propagateSwitchView"
      v-if="canShowMenuButton && visible"
    />

    <GeoModal
      :isHotel="isHotel"
      :fail="geoModalFailed"
      :onlineMenuLink="validOnlineMenuLink"
      :can_skip="cafe.geolocation_mode == 'optional'"
      @confirm="onGeoModalConfirm"
      @skip="onGeolocationSkipped"
      v-if="geoModalActive"
    />

    <PayModal
      class="pay-modal-container"
      :methods="cafe.pay_methods"
      @select="onPayModalSelect"
      @cancel="
        payModalActive = false;
        toggleGlobalScrolling(false);
      "
      v-if="payModalActive"
    />

    <PandaFatalError v-if="error" :error="error" @close="onErrorModalClose" />

    <PandaFatalError
      v-if="techincal_problems_modal"
      :error="$t('errors.tech_problem')"
      primary
      @close="techincal_problems_modal = false"
    />

    <CancelOrderModal
      :timeLeft="cancelSecondsLeft"
      @confirm="onOrderCancel"
      @cancel="hideCancelUI"
      v-if="cancelModalActive"
    />

    <DeletedDishModal
      class="deleted-dish-modal-container"
      :list="deletedDishModal.list"
      :reason="deletedDishModal.reason"
      @confirm="showOrderSomethingElseUI"
      @close="hideDeletedDishModal"
      v-if="deletedDishModal.active"
    />

    <SupermanModal
      @close="hideSupermanModal"
      v-if="supermanModalActive"
      :online="order.pay_method === 'liqpay'"
    />

    <OnlinePaymentModal
      :order_id="order._id"
      @close="onLiqpayClose"
      @success="onLiqpaySuccess"
      v-if="liqpayModalActive"
    />

    <OnlinePaymentModal
      :order_id="prepaymentAppend._id"
      @close="onAppendPrepayModalClose"
      @success="onAppendPrepayModalSuccess"
      v-if="prepaymentAppendModal"
    />

    <!-- <CafeNotPaidModal
      @close="cafeNotPaidModal = false"
      v-if="cafeNotPaidModal"
    /> -->

    <!-- <ClientInfoModal 
      @confirm="onClientInfoConfirm"
      v-if="clientInfoModal"/>

    <PhoneConfirmModal
      :token="snakePhoneConfirmToken"
      @confirmed="onPhoneConfirmed"
      @resend="askPhoneConfirmation"
      @cancel="cancelPhoneConfirmation" 
      v-if="snakePhoneConfirmModal"
    />-->

    <SnakeGame
      :source="'inside'"
      :user="snakeUser"
      @close="isPlayingGame = false"
      v-if="isPlayingGame"
    />

    <AlcoholicModal
      @confirm="onCartSend"
      @cancel="alcoModal = false"
      v-if="alcoModal"
    />

    <GiveTipsModal
      :order="order"
      :isOffline="!mainOrderSent || !order || !order._id"
      :total="mainOrderSent ? order.total : cartTotal"
      :isBeforeCheckout="!mainOrderSent || (order.status != 'paying' && order.status !== 'executed')"
      :tipping_methods="tipping_methods"
      @tipValue="(tipValue) => (lastTip = tipValue)"
      @offlineTipValue="onOfflineTip"
      @close="showTipsModal = false"
      @tipped="onTipped"
      v-if="showTipsModal"
    />

    <OrderNotAcceptedModal
      @close="
        order_not_accepted_modal = false;
        propagateSwitchView('menu');
      "
      v-if="order_not_accepted_modal"
    />

    <!-- <SupportContainer /> -->
  </div>
</template>

<style scoped>
.order-session {
  margin: 10vh 0 12.5vh;
}

p {
  width: inherit;
  margin: 0;
  font-size: 2.2vh;
  line-height: normal;
}

.empty-cart {
  text-align: center;
  margin-top: 15px;
}

.snake-button {
  margin-top: 30px;
  text-align: center;
}

.mt-20 {
  margin-top: 2.5vh;
}

.pay-total-box {
  padding: 0 2.5vh 0;
  color: #1d1d1b;
}

.pay-total-box h5 {
  color: #a4a3a1;
  font-size: 1.7vh;
}

.pay-method.dark > * {
  color: #ededed;
}

.pay-method {
  display: flex;
  color: #1d1d1b;
  padding: 2vh 0 2vh 0;
  margin-bottom: 2vh;
  width: 100%;
  border-bottom: 1px solid #a4a3a1;
  border-top: 1px solid #a4a3a1;
}

.pay-method > * {
  color: #1d1d1b;
}

.pay-method p {
  color: #ededed;
}

.pay-method img {
  height: 3.8vh;
  width: 3.8vh;
  margin-right: 2vh;
}

.bg-grey {
  background: #464646;
}

img.logo {
  height: 6.2vh;
  width: auto;
  margin: 3vh auto 0;
  display: block;
  opacity: 0.3;
}

.snake-button img {
  width: 60vw;
  height: auto;
}

.self-service-notification {
  text-align: center;
  font-size: 2.1vh;
}

.self-service-notification.dark {
  color: #ededed;
}

.bonus-balance {
  display: flex;
  justify-content: space-between;
  font-size: 2vh;
  color: #908f8d;
  padding: 1.6vh 0 1.5vh;
  border-bottom: 1px solid #a4a3a1;
}

span.bonus-value.dark {
  float: right;
  color: #ededed;
}

span.bonus-value {
  float: right;
  color: #464646;
}

p.join-cafe {
  color: #ededed;
  padding: 4vh 0 2vh;
  text-align: center;
}

p.join-cafe a {
  color: #129b81;
}

.total-price-box {
  color: #1d1d1b;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  margin: 0;
  padding: 3vh 0;
  display: flex;
  justify-content: space-between;
}

.total-price-box.dark {
  color: #ededed;
}

.total-price-box span {
  font-size: 2.7vh;
}

.feedback-text {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 3.3vh 0 6.3vh;
}

.feedback-text.dark {
  color: #ededed;
}

.feedback-text h4 {
  font-size: 2.5vh;
  border-bottom: 1px solid #1d1d1d;
  padding: 0;
  margin: 0 2vh;
}

.feedback-text img {
  align-self: baseline;
  height: 2.5vh;
  width: auto;
}

img.red-rotated {
  align-self: center;
  transform: rotate(180deg);
}

.empty-cart.dark {
  color: #ededed;
}

.empty-cart img {
  width: 25vh;
  margin-bottom: 1vh;
}

.inside-splits-container {
  padding: 0 2.5vh;
}

.inside-comment-container {
  padding: 0 2.5vh;
}

.inside-promocode-container {
  padding: 2.5vh 2.5vh 0 2.5vh;
}

.give-tips-btn {
  width: 90%;
}

.offline-give-tips-btn {
  width: 90%;
}

/* 
.total-price-box span:first-child:after {
  content: "...............................................";
  padding:0 0.5vh;
  color: #a4a3a1;

} */
</style>

<script>
import OrderButtons from "@/components/OrderButtons.vue";
import OrderCart from "@/components/OrderCart.vue";
import ContactIcons from "@/components/order-details/ContactIcons.vue";

import OrderBill from "@/components/OrderBill.vue";
import GeoModal from "@/components/GeoModal.vue";
import PayModal from "@/components/PayModal.vue";
import CancelOrderModal from "@/components/CancelOrderModal.vue";
import SupermanModal from "@/components/SupermanModal.vue";
import DeletedDishModal from "@/components/DeletedDishModal.vue";
// import LiqpayModal from "@/components/LiqpayModal.vue";
// import ErrorModal from "@/components/ErrorModal.vue";
import CafeNotPaidModal from "@/components/CafeNotPaidModal.vue";
import CompleteOrderCard from "@/components/card/CompleteOrderCard.vue";
import SnakeGame from "@/components/SnakeGame.vue";
import PandaFatalError from "@/components/modals/PandaFatalError.vue";
import ClientInfoModal from "@/components/modals/ClientInfoModal.vue";
import PhoneConfirmModal from "@/components/modals/PhoneConfirmModal.vue";
import CafeFeedback from "@/components/CafeFeedback.vue";
import PayMethods from "@/components/PayMethods.vue";
import FeedbackButton from "@/components/FeedbackButton.vue";
import SupportContainer from "@/components/SupportContainer.vue";
import OnlinePaymentModal from "@/components/modals/OnlinePaymentModal.vue";
import SupportIcon from "@/components/SupportIcon.vue";
import AlcoholicModal from "@/components/modals/AlcoholicModal.vue";
import PositionSaver from "@/services/positionsaver";
import CommentField from "@/components/order-details/CommentField.vue";
import PromocodeInput from "@/components/order-details/PromocodeInput.vue";
import GiveTipsModal from "@/components/modals/GiveTipsModal.vue";
import OrderNotAcceptedModal from "@/components/modals/OrderNotAcceptedModal.vue";

import OrderAPI from "@/api/order";
import SecurityAPI from "@/api/security";

import SplitPaymentsList from "@/components/SplitPaymentsList.vue";

import GeolocationService from "@/services/geolocation";
import CartService from "@/services/cart";
import SoundService from "@/services/sound";

import Communicator from "@/services/order/communicator.js";
import OrderSaver from "@/services/order/saver.js";

import TelemetryService from "@/services/telemetry";

import WaitersAPI from "@/api/waiters";

import moment from "moment";

export default {
  props: {
    cart: Array,
    target: Object,
    cafe: Object,
    client: String,
    requestId: String,
    visible: Boolean,
    // canPay:Boolean
  },
  data() {
    return {
      order: {},
      mainOrderSent: false,
      busy: false,

      sendData: {
        comment: null,
        geolocation: null,
        promocode: null,
      },

      lastTip: 0,
      geoModalActive: false,
      geoModalFailed: false,
      payModalActive: false,
      cancelModalActive: false,
      supermanModalActive: false,
      liqpayModalActive: false,
      liqpaySucceeded: false,
      cafeNotPaidModal: false,
      clientInfoModal: false,
      askedForGeolocation: false,

      isPlayingGame: false,

      showTipsModal: false,

      deletedDishModal: {
        active: false,
        list: [],
        reason: null,
      },

      snakeClient: {
        name: null,
        phone: null,
      },
      snakePhoneConfirmModal: null,
      snakePhoneConfirmToken: null,

      splittedBills: [],

      cancelSecondsLeft: 30,

      error: null,
      selectedPayMethod: null,
      showFeedback: false,
      feedbackSent: false,
      paymethod_validation: true,
      alcoModal: false,

      prepaymentAppend: null,
      prepaymentAppendModal: false,

      tipping_methods: {},

      techincal_problems_modal: false,

      order_not_accepted_modal: false,
    };
  },
  watch: {
    selectedPayMethod() {
      if (this.selectedPayMethod) {
        this.paymethod_validation = true;
      }
    },
    "order.status"() {
      if (this.order && this.order.status == "unpaid") {
        this.checkTipOptions();
      }
    },
  },
  methods: {
    //Propagations
    propagateSwitchView(arg) {
      this.$emit("switchView", arg);

      if (this.order.status == "cancelled") {
        this.order = {};
        this.mainOrderSent = false;
        this.$emit("incrementRequestId");
      }
    },

    //UI
    showPayMethodUI(tip) {
      if (tip) this.lastTip = tip;
      this.payModalActive = true;
      this.toggleGlobalScrolling(true);
      this.resetScroll();
    },

    startPayment() {
      if (this.order.status == "unpaid") {
        if (
          this.selectedPayMethod == "online" &&
          this.order.split_payments &&
          this.order.split_payments.length
        ) {
          this.liqpayModalActive = true;
        } else if (this.selectedPayMethod) {
          this.onPayModalSelect(this.selectedPayMethod);
        } else {
          this.paymethod_validation = false;
        }
        return;
      }

      this.order.status = "unpaid";

      //Merge order list for bill
      if (this.order.appends && this.order.appends.length) {
        this.order.appends.forEach((a) => {
          if (a.status == "serving")
            this.order.list = this.order.list.concat(a.list);
        });

        this.order.appends = [];
      }

      this.$emit("toggleServices", true);

      TelemetryService.emit(TelemetryService.Events.StartPaying, "none");

      TelemetryService.addMilestone("pay");
    },

    showCancelUI() {
      this.cancelModalActive = true;
      this.toggleGlobalScrolling(true);
    },

    hideCancelUI() {
      this.cancelModalActive = false;
      this.toggleGlobalScrolling(false);
    },

    showOrderSomethingElseUI() {
      this.deletedDishModal.active = false;

      const switch_category =
        this.deletedDishModal.list.length == 1
          ? this.deletedDishModal.list[0].category
          : null;

      this.$emit("switchView", "menu", switch_category);

      this.toggleGlobalScrolling(false);
    },
    orderOtherItems() {
      if (this.order.status != "cancelled") {
        this.propagateSwitchView("menu");
      } else {
        window.location.reload();
      }
    },

    //Events
    onCartSend() {
      if (this.busy) return;

      if (this.$store.getters["cart/hasExciseItem"] && !this.alcoModal) {
        this.alcoModal = true;
        return;
      } else {
        this.alcoModal = false;
      }

      this.busy = true;

      if (this.cafe.geolocation_mode == "disabled") {
        this.onGeolocationSkipped();
        return;
      }

      if (GeolocationService.isPermissionCached()) {
        this.requestGeolocation()
          .then(this.onGeolocationFetched)
          .catch((err) => console.error);
      } else {
        this.geoModalActive = true;
        this.toggleGlobalScrolling(true);
        this.resetScroll();
      }
    },

    onOrderCancel(reason) {
      Communicator.cancelOrder(this.order._id, reason);

      this.cancelModalActive = false;

      TelemetryService.emit(TelemetryService.Events.CancelOrder, reason);

      PositionSaver.clear("inside");
    },

    onGeolocationFetched() {
      this.toggleGlobalScrolling(false);

      if (this.mainOrderSent) {
        this.sendAppend();
      } else {
        this.sendMainOrder();
      }
    },

    onGeoModalConfirm() {
      this.requestGeolocation().then(this.onGeolocationFetched);
    },

    onPayModalSelect(_method) {
      this.payModalActive = false;
      this.toggleGlobalScrolling(false);
      if (_method === "cash" || _method === "card") {
        if (!this.isHotel) this.supermanModalActive = true;
        this.toggleGlobalScrolling(true);
      }

      if (_method === "online") {
        // this.liqpayModalActive = true;
      }

      OrderAPI.requestPayment(this.order._id, _method, this.lastTip);

      this.lastTip = 0;

      TelemetryService.emit(TelemetryService.Events.ChoosePayMethod, _method);
    },

    onCommunicatorContinuePayment() {
      if (this.selectedPayMethod == "online") {
        this.liqpayModalActive = true;
      }
    },

    onTipSelected(arg) {
      this.lastTip = arg;
    },

    onAppendCancel(append_index) {
      Communicator.cancelAppend(this.order._id, append_index);
    },

    onLiqpaySuccess() {
      this.liqpaySucceeded = true;
    },

    onAppendPrepayModalClose() {
      this.prepaymentAppendModal = false;
      this.toggleGlobalScrolling(false);
    },

    onAppendPrepayModalSuccess() {
      this.cart.splice(0, this.cart.length);
      CartService.clearCartCache("inside");
    },

    onLiqpayClose() {
      this.liqpayModalActive = false;
      this.toggleGlobalScrolling(false);

      if (this.liqpaySucceeded) {
        this.cart.splice(0, this.cart.length);
        // if (this.order.status !== "executed") this.supermanModalActive = true;
      } else {
        // this.startPayment();
      }
    },

    onErrorModalClose() {
      this.error = null;
    },
    //Communicator events
    onCommunicatorConnect() {
      this.checkOrderCache();
    },

    onCommunicatorUpdate(_order) {
      const wasInPayment = this.order.status == "unpaid";

      if (_order._id != this.order._id) {
        this.splittedBills.forEach((bill, index) => {
          if (bill._id === _order._id) {
            if (_order.status !== "executed") {
              this.splittedBills.splice(index, 1);
              return;
            }

            this.$set(this.splittedBills, index, _order);
            return;
          }
        });
      } else {
        if (this.order.status) this.compareOrdersForUI(this.order, _order);
        this.order = _order;

        if (this.order.status == "serving" && wasInPayment) {
          this.order.status = "unpaid";
        }
      }

      SoundService.playOrderUpdateSound();

      OrderSaver.save(_order);

      if (_order.status == "paying") {
        this.$emit("order-closed");
      }

      if (_order.status === "cancelled" || _order.status === "executed") {
        this.supermanModalActive = false;
        this.payModalActive = false;
        OrderSaver.clear();
        this.toggleGlobalScrolling(false);
        this.$emit("lockCart", false);

        if (_order.status === "executed") this.$emit("order-closed");

        PositionSaver.clear("inside");
        this.checkTipOptions();
        this.cart.splice(0, this.cart.length);

        if (_order.cancelled_by_system) {
          this.order_not_accepted_modal = true;
        }
      } else {
        this.$emit("lockCart", true);
        this.mainOrderSent = true;
      }

      if (_order.status == "paying") {
        if (_order.pay_method === "online") {
          //user refreshed/exited/broke page while paying via liqpay

          this.startPayment();
        }
      }

      if (
        _order.status == "cancelled" &&
        _order.reason == "Techincal problems"
      ) {
        this.techincal_problems_modal = true;
      }

      this.$emit(
        "toggleServices",
        ["paying", "executed"].includes(_order.status)
      );

      this.checkOrderMerge();

      TelemetryService.emit(TelemetryService.Events.OrderUpdate, _order.status);
    },

    onCommunicatorSwitch(order_id) {
      Communicator.listenOrder(order_id);
      Communicator.sync(order_id, this.target);
    },

    onCommunicatorBill(order) {
      this.splittedBills.push(order);

      Communicator.listenOrder(order._id);
    },

    onGeolocationSkipped() {
      this.geoModalActive = false;
      this.sendData.geolocation = {
        lat: 0,
        lon: 0,
      };
      this.onGeolocationFetched();
    },

    //Executors
    requestGeolocation() {
      if (this.askedForGeolocation) return;

      this.askedForGeolocation = true;
      return new Promise((resolve, reject) => {
        GeolocationService.getPosition()
          .then((pos) => {
            this.geoModalActive = false;
            this.geoModalFailed = null;
            this.sendData.geolocation = pos;
            this.askedForGeolocation = false;

            if (!GeolocationService.isPermissionCached()) {
              GeolocationService.cacheGeolocationPermission();
            }

            TelemetryService.emit(
              TelemetryService.Events.GiveGeolocation,
              `${pos.lat}, ${pos.lon}`
            );

            this.$emit("geolocation", this.sendData.geolocation);

            resolve();
          })
          .catch((err) => {
            this.busy = false;
            this.askedForGeolocation = false;
            // this.error = this.$t(`errors.${err}`);

            this.geoModalFailed = "browser";

            reject(err);
          });
      });
    },

    sendMainOrder() {
      OrderAPI.createOrder({
        target: this.target,
        cart: this.cart,
        geolocation: this.sendData.geolocation,
        client_id: this.$store.state.clients.client_id,
        client_name: this.$store.getters["clients/isAuthorized"]
          ? this.$store.state.clients.client.name
          : null,
        request_id: this.requestId,
        comment: this.sendData.comment,
        promocode: this.sendData.promocode,
      })
        .then((data) => {
          const order = data.order;

          this.order = order;

          this.$emit("lockCart", true);

          Communicator.listenOrder(order._id);

          if (this.order.status !== "pending") {
            this.mainOrderSent = true;
            this.cart.splice(0, this.cart.length);
            OrderSaver.save(this.order);
            CartService.clearCartCache("inside");
          } else {
            this.liqpayModalActive = true;
          }

          this.busy = false;

          TelemetryService.emit(TelemetryService.Events.SendOrder, order._id);
          TelemetryService.addMilestone("order");
        })
        .catch((err) => {
          this.busy = false;

          if (err === "cafe_not_paid") {
            this.cafeNotPaidModal = true;
          }
          if (err == "access_denied") {
            this.geoModalFailed = "not_in_cafe";
            this.geoModalActive = true;
          } else {
            this.error = this.$t(`errors.${err}`);
          }
        });
    },

    sendAppend() {
      if (this.cafe.prepayment_required) {
        OrderAPI.createOrder({
          target: this.target,
          cart: this.cart,
          geolocation: this.sendData.geolocation,
          client_id: this.$store.state.clients.client_id,
          client_name: this.$store.getters["clients/isAuthorized"]
            ? this.$store.state.clients.client.name
            : null,
          request_id: Math.random().toString(36),
          parent_id: this.order._id,
          comment: this.sendData.comment,
          promocode: this.sendData.promocode,
        })
          .then((data) => {
            this.prepaymentAppend = data.order;
            this.prepaymentAppendModal = true;

            this.$emit("lockCart", true);

            this.busy = false;

            TelemetryService.emit(
              TelemetryService.Events.SendOrder,
              "*append*"
            );
          })
          .catch((err) => {
            this.busy = false;

            if (err === "cafe_not_paid") {
              this.cafeNotPaidModal = true;
            }
            if (err == "access_denied") {
              this.geoModalFailed = "not_in_cafe";
              this.geoModalActive = true;
            } else {
              this.error = this.$t(`errors.${err}`);
            }
          });
      } else {
        OrderAPI.sendAppend(
          this.order._id,
          this.cart,
          this.sendData.geolocation,
          this.sendData.comment
        )
          .then((ok) => {
            this.cart.splice(0, this.cart.length);
            CartService.clearCartCache("inside");

            this.busy = false;

            TelemetryService.emit(
              TelemetryService.Events.SendOrder,
              "*append*"
            );
          })
          .catch((err) => {
            this.error = err;
          });
      }
    },

    checkOrderCache() {
      if (OrderSaver.isActual(this.target.cafe_id)) {
        this.order = OrderSaver.load();
        this.mainOrderSent = true;

        this.$emit("lockCart", true);

        Communicator.listenOrder(this.order._id);
        Communicator.sync(this.order._id, this.target);

        if (this.order.pay_method === "online") {
          //user refreshed/exited/broke page while paying via liqpay

          this.startPayment();
        }

        this.checkTipOptions();
      }
    },

    checkOrderMerge() {
      if (this.order.status === "merged" || this.order.merged_id) {
        this.onCommunicatorSwitch(this.order.merged_id);
      }
    },

    checkTipOptions() {
      WaitersAPI.getTipOptions(this.order._id).then((data) => {
        this.tipping_methods = data.tipping_methods;
      });
    },

    compareOrdersForUI(oldOrder, newOrder) {
      if (oldOrder.status !== newOrder.status) {
        const newStatus = newOrder.status;

        if (newStatus === "serving") {
          const deletedItems = newOrder.list.filter((it) => !it.available);

          if (deletedItems.length > 0) {
            this.deletedDishModal.active = true;
            this.deletedDishModal.list = deletedItems;
            this.deletedDishModal.reason = newOrder.reason;
          }
        }

        if (newStatus != "executed" && newStatus != "cancelled") {
          CartService.clearCartCache("inside");

          OrderSaver.save(newOrder);
        }

        if (newStatus == "waiting" && oldOrder.status == "pending") {
          this.cart.splice(0, this.cart.length);

          this.checkTipOptions();
        }

        if (newStatus == "serving") {
          this.checkTipOptions();
        }
      }

      if (
        newOrder.status !== "paying" &&
        newOrder.list.length != oldOrder.list.length
      ) {
        this.checkTipOptions();
      }

      if (newOrder.appends && newOrder.appends.length > 0) {
        let appends_deleted_items = [];

        oldOrder.appends.forEach((left, index) => {
          const right = newOrder.appends[index];

          if (left.status != right.status && right.status === "serving") {
            appends_deleted_items = appends_deleted_items.concat(
              right.list.filter((it) => !it.available)
            );
          }
        });

        if (appends_deleted_items.length > 0) {
          this.deletedDishModal.active = true;
          this.deletedDishModal.list = appends_deleted_items;
          this.deletedDishModal.reason = newOrder.reason;
        }

        if (
          newOrder.appends.length != oldOrder.appends.length &&
          this.cafe.prepayment_required
        ) {
          this.cart.splice(0, this.cart.length);
          CartService.clearCartCache("inside");
        }
      }

      this.toggleGlobalScrolling(this.deletedDishModal.active);
    },

    hideDeletedDishModal() {
      this.deletedDishModal.active = false;
      this.toggleGlobalScrolling(false);
    },

    hideSupermanModal() {
      this.toggleGlobalScrolling(false);
      this.supermanModalActive = false;
    },

    tryToOpenSnake() {
      this.isPlayingGame = true;
    },
    onFeedbackSent() {
      this.showFeedback = false;
      this.feedbackSent = true;
    },
    closeFeedback() {
      this.showFeedback = false;
    },
    onCancelTimerTick(t) {
      this.cancelSecondsLeft = t;
      if (this.cancelSecondsLeft <= 0) this.cancelModalActive = false;
    },

    onTipped() {
      this.showTipsModal = false;
      this.tipping_methods = {};
    },

    onOfflineTip(tip) {
      this.lastTip = tip;
      this.$store.dispatch("cart/addItem", {
        name: "Tips",
        category: "Tips",
        available: true,
        quantity: 1,
        variant: null,
        price: tip,
        metatype: "tips",
      });
    },
  },

  computed: {
    timerActive() {
      setTimeout(() => (this.isTimerActive = false), 2500);
      return this.isTimerActive;
    },
    cartNumber() {
      if (this.mainOrderSent) return 2 + (this.order.appends || []).length;

      return 1;
    },
    hasTipInOrder() {
      if (!this.order || !this.order.list) return false;
      return !!this.order.list.find((it) => it.metatype == "tips");
    },
    canPay() {
      if (!this.mainOrderSent) return false;
      if (this.order.status !== "serving") return false;
      if (this.order.paid_at) return false;
      if (
        this.order.appends &&
        this.order.appends.filter((ap) => ap.status === "waiting").length > 0
      ) {
        return false;
      }

      return true;
    },
    isShowingBill() {
      return ["executed", "paying"].includes(this.order.status);
    },
    isPaying() {
      return ["executed", "paying", "unpaid"].includes(this.order.status);
    },
    canShowMenuButton() {
      if (!this.mainOrderSent) {
        return true;
      }

      return ["serving", "waiting", "unpaid", "cancelled"].includes(
        this.order.status
      );
    },
    reversedAppends() {
      return this.order.appends.slice().reverse();
    },
    pendingAppends() {
      return this.order.appends.filter(
        (ap) => ap.status === "waiting" || ap.status === "cancelled"
      );
    },
    snakeUser() {
      if (this.$store.getters["clients/isAuthorized"]) {
        const client = this.$store.state.clients.client;

        return `${client.name} ${client.phone}`;
      } else {
        return `${this.snakeClient.name} ${this.snakeClient.phone}`;
      }
    },
    showSelfServiceNotification() {
      if (!this.order) return false;

      return !!this.order.served_at && this.cafe.self_service;
    },
    isFromApp() {
      return this.$store.getters["clients/isAuthorized"];
    },
    clientBonusBalance() {
      if (!this.isFromApp) return null;
      if (!this.isLoyaltyEnabled) return null;

      return this.$store.getters["clients/bonusBalance"];
    },
    isLoyaltyEnabled() {
      return (
        this.cafe.loyalty &&
        this.cafe.loyalty.cashback_percent &&
        this.cafe.loyalty.terms_agreed
      );
    },
    canSend() {
      if (this.currentMode != "inside") return false;
      return this.$store.state.cart.items.length;
    },
    canPayByBonus() {
      if (!this.clientBonusBalance) return false;
      return this.order.total <= this.clientBonusBalance;
    },
    isHotel() {
      return this.cafe.primary_category == "hotel";
    },
    validOnlineMenuLink() {
      if (
        !this.cafe.modes.preorder &&
        !this.cafe.modes.delivery &&
        !this.cafe.modes.reserve
      )
        return null;

      return this.cafe.online_menu_link;
    },
    cartTotal() {
      return this.$store.getters["cart/total"];
    },
    hasTipsInCart() {
      return this.$store.getters["cart/hasMetaItem"]("tips");
    },
  },
  created() {
    SoundService.init();

    Communicator.connect({
      onConnect: this.onCommunicatorConnect,
      onUpdate: this.onCommunicatorUpdate,
      onSwitch: this.onCommunicatorSwitch,
      onBill: this.onCommunicatorBill,
      onContinuePayment: this.onCommunicatorContinuePayment,
    });
  },
  components: {
    OrderCart,
    OrderButtons,
    ContactIcons,
    GeoModal,
    PayModal,
    CancelOrderModal,
    DeletedDishModal,
    OrderBill,
    SupermanModal,
    // LiqpayModal,
    // ErrorModal,
    CompleteOrderCard,
    CafeNotPaidModal,
    SnakeGame,
    PandaFatalError,
    ClientInfoModal,
    PhoneConfirmModal,
    CafeFeedback,
    PayMethods,
    FeedbackButton,
    OnlinePaymentModal,
    SupportContainer,
    SupportIcon,
    AlcoholicModal,
    SplitPaymentsList,
    CommentField,
    PromocodeInput,
    GiveTipsModal,
    OrderNotAcceptedModal,
  },
};
</script>