import confetti from 'canvas-confetti'

const default_colors = [
    '#F5F5F5',
    '#191919',
    '#808080',
    '#15bd95',
    '#f5273f',
    '#f7cda3',
    '#ff7d26',
    '#A0B9EB'
];


function fireBirthdayConfetti(colors = default_colors) {
    confetti({
        angle: 80,
        spread: 50,
        particleCount: 75,
        origin: {
            y: 0.5,
            x: 0
        },
        ticks: 220,
        colors
    });

    confetti({
        angle: 110,
        spread: 50,
        particleCount: 75,
        origin: {
            y: 0.5,
            x: 1
        },
        ticks: 220,
        colors
    });
}

export default {
    fireBirthdayConfetti
}