import BaseAPI from '../base'

function roll(client_id, cafe_id, telemetry_id) {
    return BaseAPI.sendPost(`/casino/roll`, {
        client_id,
        cafe_id,
        telemetry_id
    });
}

function confirm(roll_id) {
    return BaseAPI.sendPost(`/casino/confirm`, {
        roll_id
    });
}

function resend(roll_id) {
    return BaseAPI.sendPost(`/casino/resend/${roll_id}`);
}

export default {
    roll,
    resend,
    confirm
}