<template>
  <div class="greeting-page-wrapper">
    <div class="cafe-logo">
      <img :src="cafe.image" alt="cafe-logo" class="cover" />

      <div class="active-order" 
        @click="activeOrderModal = true" 
        v-if="active_order">
        <span>i</span>
        <p class="m-0 p-2">{{ $t('active_order.check') }}</p>
      </div>
    </div>

    <div class="how2use" @click="onTutorialOpen" v-if="canShowTutorial">
      <span>i</span>
      <p class="m-0 p-2">{{ $t('greeting.tutorial') }}</p>
    </div>

    <div class="cafe-info">
      <!-- <img class="cafe-image" :src="cafe.image" alt="Locale Flag" @click="showSchedule"/> -->
      <h3 class="pt-4">{{ cafe.name }}</h3>
      <img
        class="locale-image"
        :src="`/flags/${currentLocale}.png`"
        alt="Locale Flag"
        @click="showLanguages"
      />
      <span class="descr pb-1">{{ cafe.description }}</span>
      <span style="color:#999999" v-if="!target.preorder">{{$t('greeting.table')}} №{{target.table}}</span>
      <span style="color:#999999" v-if="target.preorder">{{$t('greeting.preorder')}}</span>

      <div v-if="isOpen && subscription" class="open">
        <button class="make-order mt-2" @click="makeOrder">{{ $t('greeting.make_order') }}</button>
        <small
          @click="showSchedule"
          class="till-close py-4"
          :class="{
            'till-close': true,
            'closing-soon': isClosingSoon 
          }"
        >{{ $t('greeting.until_close_left') }} {{ remainingTime }}</small>
      </div>

      <div v-if="!isOpen">
        <h5 class="py-3">{{ $t('greeting.closed') }}</h5>
      </div>
      <div v-if="!subscription">
        <h5 class="pt-3 pb-1 w-50 m-auto">{{ $t('greeting.not_paid') }}</h5>
      </div>
    </div>

    <!-- <div v-if="activeHow2Use">
      <div class="hide-content"></div>
      <div class="how2useModal">
        
        <video autoplay loop playsinline>
          <source src="../assets/how2use.mp4" type="video/mp4">
        </video>
        <button class="ok" @click="onTutorialClose">OK</button>
        
      </div> 
    </div> -->

    <div v-if="activeOrderModal">
      <ActiveOrderModal 
        :order="active_order" 
        @cancel="onActiveOrderCancel"
        @accept="onActiveOrderAccept"/>
    </div>

    <div v-if="languageModal">
      <div class="hide-content"></div>
      <div class="select-language">
        <span class="close" @click="languageModal = false">x</span>
        <h4>{{ $t('greeting.choose_language') }}</h4>
        <img class="lan-img" :src="`/flags/uk.png`" @click="switchLocale('uk')" />
        <p>Українська</p>
        <img class="lan-img" :src="`/flags/ru.png`" @click="switchLocale('ru')" />
        <p>Русский</p>
        <img class="lan-img" :src="`/flags/en.png`" @click="switchLocale('en')" />
        <p>English</p>
      </div>
    </div>

    <div v-if="scheduleModal">
      <CafeSchedule :schedule="cafe.schedule" @close="scheduleModal = false" />
    </div>
  </div>
</template>

<style scoped>
h3 {
  font-size: 28px;
  margin-bottom: 0;
  font-weight: bold;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.descr {
  font-weight: 600;
  color: black;
  font-size: 17px;
  opacity: 0.4;
}

h5 {
  font-size: 18px;
  font-weight: bold;
}


@keyframes active-order {
  0% {
    left: -50px;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes action-border {
  0% {
    box-shadow: 0px 0px 3px #000000;
  }
  50% {
    box-shadow: 0px 0px 15px #000000;
  }
  100% {
    box-shadow: 0px 0px 3px #000000;
  }
}

.how2useModal {
  /* transform: translateY(-62%);
  max-height:100%;
  margin: 0 auto;
  width: fit-content; */

  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 10px;
  text-align: center;
}



.how2useModal video {
  max-width: 80vw;
  border-radius: 20px;
}

button.ok {
  background: #129B81;
  font-size: 20px;
  color: #ffffff;
  padding: 8px 30px;
  border:none;
  margin: auto;
  
  box-shadow: 0px 4px 1px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
}

.active-order, .how2use {
  top: 40px;
  position: absolute;
  left: 20px;
  animation: active-order 0.5s, action-border 1.5s infinite;
  position: absolute;
  display: flex;
  width: 75%;
  background: #ffffff;
  border-radius: 35px;
}

.active-order span, .how2use span {
  background: #262626;
  padding: 7px 25px 0 18px;
  margin: 8px 0 8px 8px;
  border-radius: 50px;
  font-style: italic;
  font-weight: bold;
  font-size: 24px;
  color: white;
}

.close {
  background: white;
  padding: 5px 10px 6px;
  right: 22px;
  font-size: 18px;
  position: absolute;
  color: #ff5c5c;
  float: right;
  font-weight: 600;
  border-radius: 50px;
  opacity: 1;
  box-shadow: 2px 2px 8px lightgray;
}

.hide-content {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 0;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.8);
  opacity: 1;
  top: 0;
  left: 0;
}

.introduce,
.select-language,
.schedule {
  box-shadow: 0px 0px 5px #dddddd;
  border-radius: 40px;
  display: block;
  background: white;
  margin: 0 auto;
  width: 90%;
  text-align: center;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  padding: 20px 0 30px;
  position: absolute;
}

.schedule h4 {
  color: #545454;
  font-weight: 500;
  margin-top: 3px;
  font-size: 20px;
}

.select-language {
  max-height: 88%;
  overflow-y: auto;
}

.select-language h4 {
  font-size: 20px;

  margin: 2px 0 20px;
}

.select-language img {
  height: 90px;
}

.open {
  display: grid !important;
}

.till-close {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
}

.cafe-logo img {
  animation: slide-in 1s; 
  width: 100%;
  margin: 0 auto;
  min-height: 75vh;
  object-fit: cover;
}

.locale-image {
  height: 30px;
  position: absolute;
  right: 28px;
  margin-top: -33px;
}

.cafe-image {
  height: 40px;
  position: absolute;
  left: 35px;
  margin-top: 25px;
}

button.make-order {
  margin: 0 auto;
  background: linear-gradient(180deg, #5EA7FF 0%, #2F8EFF 100%);
  box-shadow: inset 0px -4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  width: 80%;
  padding: 15px 0;
  outline: none;
  font-size: 16px;
  border: none;
  color: white;
  font-weight: 500;
}

.cafe-info {
  animation: slide-out 1s;
  background: #ffffff;
  border-radius: 40px 40px 0px 0px;
  min-height: auto;
  width: 100%;
  text-align: center;
  position: fixed;
  bottom: 0;
}

@keyframes slide-in {
  0% {
    position: absolute;
    opacity: 0;
    top: -100px;
  }
  100% {
    top: 0;
    position: relative;
    opacity: 1;
  }
}

@keyframes slide-out {
  0% {
    opacity: 0;
    bottom: -100px;
  }
  100% {
    bottom: 0;
    opacity: 1;
  }
}

.cafe-info h3 {
  max-width: 250px;
  margin: 0 auto;
}

.cafe-info > * {
  display: block;
}

.closing-soon {
  color: red;
}
</style>


<script>
import ScheduleService from "@/services/schedule";

import CafeSchedule from "@/components/CafeSchedule.vue";

import Polyglote from "@/services/polyglote";

import ActiveOrderModal from '@/components/ActiveOrderModal.vue';

import OrderAPI from '@/api/order'

import TelemetryService from '@/services/telemetry';

export default {
  data() {
    return {
      scheduleString: "",
      remainingTime: "",
      isClosingSoon: false,
      isOpen: false,
      noAccess: false,
      requestingClientName: false,
      languageModal: false,
      scheduleModal: false,
      clientName: "",
      currentLocale: "uk",
      activeOrderModal: false,
      activeHow2Use: false,

      isFreshman: false
    };
  },
  props: {
    cafe: Object,
    target: Object,
    subscription: Boolean,
    active_order: Object
  },
  methods: {
    showClientModal() {
      this.requestingClientName = true;
    },

    showSchedule() {
      this.scheduleModal = true;
    },

    showLanguages() {
      this.languageModal = true;
    },

    onActiveOrderCancel() {
      this.activeOrderModal = false;
      this.$emit('cancelActiveOrder');

      OrderAPI.clearActiveOrder(this.active_order.cafe_id, this.active_order.section, this.active_order.table);
      TelemetryService.emit(TelemetryService.Events.UseActiveOrder, 'No');
    },

    onActiveOrderAccept() {
      this.$emit('acceptActiveOrder');

      OrderAPI.clearActiveOrder(this.active_order.cafe_id, this.active_order.section, this.active_order.table);
      TelemetryService.emit(TelemetryService.Events.UseActiveOrder, 'Yes');
    },

    makeOrder() {
      const trueClientName = this.clientName ? this.clientName : "";

      this.$emit("makeOrder", trueClientName);
      TelemetryService.emit(TelemetryService.Events.MakeOrder, trueClientName);
    },

    processSchedule() {
      const helper = new ScheduleService.ScheduleHelper(this.cafe.schedule);

      if (helper.isOpen()) {
        this.isOpen = true;

        this.scheduleString = helper.format();

        if (!this.scheduleString) {
          this.scheduleString = this.$t("greeting.free_day");
        }

        this.remainingTime = helper.remainingTime(
          this.$t("units.hours"),
          this.$t("units.minutes")
        );

        this.isClosingSoon = helper.isClosingSoon();
      } else {
        this.isOpen = false;
      }
    },
    loadLocale() {
      this.currentLocale = Polyglote.getSavedLocale();
    },
    switchLocale(locale) {
      Polyglote.updateLocale(locale);

      this.$i18n.locale = locale;
      this.currentLocale = locale;

      this.languageModal = false;

      this.processSchedule();

      TelemetryService.emit(TelemetryService.Events.ChangeLocale, locale);

      this.$store.dispatch('menu/tryLocale', Polyglote.getSavedLocale());
      this.$store.commit('menu/buildDictionaries');
    },
    checkFreshman() {
      if (window.localStorage.getItem("first_visit") !== null) {
        this.isFreshman = false;
      } else {
        window.localStorage.setItem("first_visit", `${new Date()}`);
        this.isFreshman = true;
      }
    },
    onTutorialOpen() {
      this.activeHow2Use = true;
      document.body.style.overflowY = '';

      TelemetryService.emit(TelemetryService.Events.ViewTutorial);
    },
    onTutorialClose() {
      this.activeHow2Use = false;
      document.body.style.overflowY = 'initial';
    }
  },
  computed: {
    canShowTutorial() {
      return !this.active_order && this.isFreshman;
    }
  },
  mounted() {
    this.processSchedule();
    this.loadLocale();
    this.checkFreshman();
  },
  components: {
    CafeSchedule,
    ActiveOrderModal
  }
};
</script>