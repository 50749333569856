<template>
  <div class="like-block" @click="$emit('click')">
    <img :src="isDarkMode ? '/icons/heart-block-dark.png' : '/icons/heart-block.png'" class="likes" alt />
    <img src="/icons/like.svg" alt="like" class="like" :style="heartStyle" />
  </div>
</template>

<script>
const HeartStyles = {
  "0%": {
    display: "none",
  },
  "-%": {
    height: "9.2vh",
  },
  "5%": {
    "margin-top": "3.2vh",
    height: "14vh",
  },
  "10%": {
    height: "19.5vh",
    "margin-top": "0.3vh",
  },
  "15%": {
    "margin-top": "-3vh",
    height: "25vh",
  },
};

export default {
  props: {
    value: Number,
  },
  computed: {
    heartStyle() {
      let max_style = 0;
      const percentages = [0, 5, 10, 15];

      for (const p of percentages) {
        if (this.value >= p) max_style = p;
      }

      return HeartStyles[`${max_style}%`] || HeartStyles["0%"];
    },
  },
};
</script>

<style scoped>
.like-block {
  position: relative;
  left: 0;
  top: 0;
  margin-top: 3.7vh;
}

.mt {
  margin-top: 3.7vh;
}

.likes {
  position: relative;
  width: auto;
  height: 21.6vh;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 auto;
  display: inline-block;
}

.like {
  position: absolute;
  transition: 0.3s;
  left: 0;
  height: 14vh;
  right: 0px;
  margin: 5.8vh auto 0;
  width: auto;
}
</style>