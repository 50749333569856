<template>
  <div>
    <div class="hide-content"></div>
    <div class="pay-methods">
      <img src="/icons/del-white.png" class="close" @click="cancel" />

      <div class="option-box" @click="select('bonus')" v-if="bonus">
        <img class="choose-pay bonus" src="/icons/pay-bonus.svg" alt="Pay by bonus" />
        <p class="color-white">{{ $t('pay.method_bonus') }}</p>
      </div>
      <div class="option-box space" @click="select('online')" v-if="methods.online">
        <img class="choose-pay online" src="/icons/liqpay.svg" alt="Pay as Online" />
        <p class="color-white">{{ $t('pay.method_liqpay') }}</p>
      </div>
      <div class="option-box space" @click="select('card')" v-if="methods.card">
        <img class="choose-pay l-1" src="/icons/card.svg" alt="Pay by card" />
        <p class="color-white">{{ $t('pay.method_card') }}</p>
      </div>
      <div class="option-box space" @click="select('cash')" v-if="methods.cash">
        <img class="choose-pay r-1" src="/icons/cash.svg" alt="Pay by cash" />
        <p class="color-white">{{ $t('pay.method_cash') }}</p>
      </div>
      <!-- <div class="cash" @click="select('cash')" v-if="methods.cash">
        <img src="/icons/cash.png" alt="Pay by cash" />
        <p>Розрахуватись готівкою</p>
      </div> -->
    </div>
  </div>
</template> 

<style scoped>
.pay-methods {
  background: #1d1d1b;
  border-radius: 5vw;
  display: flex;
  padding: 2.3vh 3.7vh;
  flex-flow: column nowrap;
  margin: 0 auto;
  transition: 0.3s;
  overflow-y: hidden;
  height: fit-content;
  z-index: 5;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);

  width: 90vw;
}

.option-box {
  padding: 3.7vh 0 0;
  display: flex;
  justify-content: flex-start;
}


.hide-content {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 2;
  overflow: hidden;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.4);
  opacity: 1;
  top: 0;
  left: 0;
}

.space {
  padding-top: 2vh;
}

p {
  font-size: 2.3vh;
  align-self:center;
  padding: 0 0 0 2.3vh;
  display: block;
  line-height: normal;
  margin-bottom: 0;
}

img.close {
  width: auto;
  height: 4.2vh;
  opacity: 1;
  right: 5.5vw;
  position: absolute;
}

img.choose-pay {
  height: 9vh;
  width:auto;
  display: block;
  position: relative;
}

img.bonus {
  height: 11vh !important;
}

img.online {
  height: 11vh !important;
}

.r-1 {right: 1.3vh;}
.l-1 {left: 0.5vh;}

.bonus-icon {
  right: -0.8vh;
}
</style>

<script>
export default {
  props: {
    methods: Object,
    bonus: Boolean
  },
  data() {
    return {
      cashOrCard: false
    };
  },
  methods: {
    select(method) {
      this.$emit("select", method);
      this.cashOrCard = true;
    },
    cancel() {
      this.$emit("cancel");
    }
  }
};
</script>