<template>
  <div>
    <Navbar
      ref="navbar"
      :cafe="cafe"
      :view="currentView"
      :target="target"
      :menu="currentMenu"
      :geolocation="lastGeo"
      :canShowGift="canShowCasinoGift"
      @giftClicked="casinoModalShown = true"
      :showArrowBack="showArrowBack"
      @go-back="navigateBack"
      :hide_services="hideServices"
      @toggleCallServices="v => navbar_showing_services = v"
    />

    <div @click="tryCollapseNavbar" v-show="!navbar_showing_services">
      <OrderMenu
        ref="orderMenu"
        :cafe="cafe"
        :menu="currentMenu"
        :menu_index="currentMenuIndex"
        :tagged_menus="menus"
        :cart="cart"
        :cartLocked="lockCartButton"
        :target="target"
        :showServices="cafe.call_services && cafe.call_services.length"
        @servicesClicked="toggleCallServices"
        @switchView="onSwitchView"
        @switchMenu="switchToMenu"
        @menuViewChange="v => menu_view = v"
        v-if="currentView === 'menu'"
      />

      <OrderSession
        ref="orderSession"
        :cart="cart"
        :target="target"
        :cafe="cafe"
        :client="client"
        :requestId="requestId"
        @switchView="onSwitchView"
        @geolocation="onGeolocationSet"
        @toggleServices="v => hideServices = v"
        @lockCart="v => lockCartButton = v"
        @order-closed="orderHasBeenClosed = true"
        @incrementRequestId="v => $emit('incrementRequestId')"
        :visible="currentView == 'cart'"
        v-show="currentView === 'cart'"
      />
    </div>
    <ActiveOrderModal
      :cafe="cafe"
      :order="active_order"
      @accept="onActiveOrderAccept"
      @cancel="onActiveOrderCancel"
      v-if="active_order && !target.lite"
    />

    <PandaFatalError
      :error="$t(`cafe_closed.title`)"
      primary
      noButton
      v-if="cafeClosed"
    />

    <CasinoModal
      :cafe="cafe"
      :menus="menus"
      @win="onAddToCart"
      @close="onCasinoClose"
      v-if="casinoModalShown"
    />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";

import OrderMenu from "@/components/OrderMenu.vue";
import OrderSession from "@/components/OrderSession.vue";

import CasinoModal from "@/components/casino/CasinoModal.vue";
import ActiveOrderModal from "@/components/ActiveOrderModal.vue";

import CartService from "@/services/cart";

import ScheduleService from "@/services/schedule";

import PandaFatalError from "@/components/modals/PandaFatalError.vue"

import OrderSaver from "@/services/order/saver.js";

import TelemetryService from "@/services/telemetry";

import OrderAPI from '@/api/order';

import EffectsService from '@/services/effects'

import moment from "moment";

export default {
  props: {
    cafe: Object,
    menus: Array,
    target: Object,
    client: String,
    requestId: String,
    active_order: Object,
  },
  data() {
    return {
      // cart: [],
      lastGeo: null,

      currentView: "menu",

      casinoModalShown: false,
  
      lockCartButton: false,

      navbar_showing_services: false,

      currentMenuIndex: 0,
      orderHasBeenClosed:false,
      hideServices: false,
      menu_view: 'categories'
    };
  },
  watch: {
    currentMenuIndex() {
      this.$store.commit('menu/setSelectedIndex', this.currentMenuIndex);
    }
  },
  methods: {
    navigateBack(){
      if (this.currentView == "cart") {
        this.currentView = "menu";
      } else {
        this.$refs.orderMenu.onBackArrowPressed();
      }
      //  if (this.currentView == "details") {
      //   this.currentView = "menu";
      // }
    },
    onSwitchView(to, payload) {
      this.$saveScroll(`view_${this.currentView}`);

      if (payload == 'menu' || (this.currentView == 'cart' && to == 'menu')) this.menu_view = 'categories';

      this.currentView = to;
      this.$restoreScroll(`view_${this.currentView}`);

      

      if (payload && to == "menu") {
        this.$nextTick(() => {
          this.$refs.orderMenu.onCategorySelect(payload);
        });
      }

      this.$emit('switchView', this.currentView)
    },
    onAddToCart(item) {
      this.$store.dispatch("cart/addItem", item);
    },

    onActiveOrderCancel() {
      // this.$emit("cancelActiveOrder");

      OrderAPI.clearActiveOrder(
        this.active_order.cafe_id,
        this.active_order.section,
        this.active_order.table
      );
      TelemetryService.emit(TelemetryService.Events.UseActiveOrder, "No");

      this.checkCasino();

      this.active_order = null;
    },

    onActiveOrderAccept() {
      // this.$emit("acceptActiveOrder");

      OrderAPI.clearActiveOrder(
        this.active_order.cafe_id,
        this.active_order.section,
        this.active_order.table
      );
      this.$emit('acceptActiveOrder');
      OrderSaver.save(this.active_order);
      this.$refs.orderSession.checkOrderCache();
      this.$nextTick(() => {
        TelemetryService.emit(TelemetryService.Events.UseActiveOrder, "Yes");

        this.checkCasino();

        this.active_order = null;
        this.currentView  = 'cart';
        this.menu_view = 'details';
      });
    },

    switchToMenu(index) {
      this.currentMenuIndex = index;

      this.$store.commit("menu/setMenu", this.menus[this.currentMenuIndex]);
      this.$store.commit("menu/buildDictionaries");
    },

    onCasinoClose() {
      this.casinoModalShown = false;
      this.toggleGlobalScrolling(false);
    },

    checkCasino() {
      // if (this.menus[0].roulette && this.menus[0].roulette.enabled) {
      if (true) {
        // const last_casino = window.localStorage.getItem("last_casino");

        // window.localStorage.setItem("last_casino", new Date());

        // if (!last_casino) {
        //   this.casinoModalShown = true;
        // } else {
        //   const then = moment(last_casino);

        //   if (moment().diff(then, "hours") > 6) {
        //     this.casinoModalShown = true;
        //   }
        // }

        // this.casinoModalShown = true;

        // if (this.casinoModalShown) {
          
        // }
      }
    },

    checkOrderCache() {
      if (OrderSaver.isActual(this.target.cafe_id) && !this.active_order) {
        this.currentView = "cart";
      } else {
        // this.checkCasino();
        this.launchEffects();
        if (this.cafe.motd && !this.cafeClosed && !this.active_order) this.$emit('showMotdModal');
      }
    },
    pushHistoryState() {
      window.history.pushState(null, document.title, window.location.href);
    },
    tryDisablingBackButton() {
      this.pushHistoryState();
      window.addEventListener("popstate", (event) => {
        window.history.go(1);

        if (this.currentView === "menu") {
          this.$refs.orderMenu.onNativeBackButtonPressed();
        }
      });
    },
    onGeolocationSet(g) {
      this.lastGeo = g;
    },
    determineModals() {
      if (!this.active_order) {
        this.checkCasino();
      }
    },
    tryCollapseNavbar() {
      if (this.$refs && this.$refs.navbar && this.$refs.navbar.collapseNavbar) {
        this.$refs.navbar.collapseNavbar();
      }
    },
    launchEffects() {
      //YEAHNOT HARDCODE
      // if (this.cafe._id == 55 && moment().isAfter('2021-08-13T06:00:00.142Z')) setTimeout(() => {
      //   EffectsService.fireBirthdayConfetti(
      //     [
      //       '#DD412D',
      //       '#F3B2CA',
      //       '#FFFFFF',
      //       '#FFDC50',
      //       '#202020',
      //       '#91BEDD'
      //     ]
      //   );
      // }, 1000);
    },
    toggleCallServices() {
      if (this.$refs.navbar) {
        this.$refs.navbar.toggleCallServices();
      }
    }
  },
  mounted() {
    this.determineModals();
    this.checkOrderCache();
    this.tryDisablingBackButton();
  },
  computed: {
    currentMenu() {
      return this.menus[this.currentMenuIndex];
    },
    cart() {
      return this.$store.state.cart.items;
    },
    showArrowBack() {
      if (this.orderHasBeenClosed) return false;
      
      if (this.currentView == 'menu') {
        return this.menu_view == 'details';
      }
      if (this.currentView == 'cart') return true;
      // if (this.currentView == 'details') return true;
    },
    cafeClosed() {
      if (!this.cafe || !this.cafe.schedule) return true;
      
      //SECURITY THROUGH OBSCRUITY:
      //dont create fucking token model for just a test order
      //just hardcode a token so everyone will think
      //it's secure
      if (this.$route.query.test_token && this.$route.query.test_token == 'rfOQ1ht') {
        return false;
      }

      const helper = new ScheduleService.ScheduleHelper(this.cafe.schedule);

      return !helper.isOpen();
    },
    canShowCasinoGift() {
      if (!this.cafe.roulette) return false;
      if (!this.cafe.roulette.enabled) return false; 

      if (this.cafe.roulette.start_date && moment().isBefore(
        this.cafe.roulette.start_date
      )) return false;

      if (this.cafe.roulette.end_date && moment().isAfter(
        this.cafe.roulette.end_date
      )) return false;

      if (this.cafe.roulette.modes && !this.cafe.roulette.modes.inside) return false;

      return !this.lockCartButton && this.currentView == 'menu';
    }
  },
  components: {
    Navbar,
    OrderMenu,
    OrderSession,
    PandaFatalError,
    CasinoModal,
    ActiveOrderModal,
  },
};
</script>