<template>
  <div class="give-tips-modal" v-thematic>
    <div class="give-tips-content" v-thematic v-if="!tipped">
      <p class="give-tips-title" v-thematic>
        {{ $t('tips_modal.title') }}
      </p>
      <HeartIcon :value="tipPercent" />
      <p class="give-tips-percent" v-thematic>
        {{ tipPercent || 0 }}% {{ $t("pay.tip_amount") }}
      </p>
      <input
        type="number"
        v-model.number="tip"
        min="0"
        maxlength="9"
        class="give-tips-input"
        :placeholder="$t('pay.choose_tip_summ')"
      />
      <PayMethods
        class="give-tips-paymethods"
        :available_methods="tipping_methods"
        v-model="pay_method"
        v-if="!isBeforeCheckout"
      />
      <div @click="onConfirm" :class="{
        'give-tips-pay-button': true,
        'give-tips-pay-button-disabled': !tip || (!isBeforeCheckout && !pay_method)
      }">
        {{ isBeforeCheckout ? $t('tips_modal.btn_add') : $t('tips_modal.btn_pay') }}
      </div>
      <div @click="onClose" class="give-tips-back-button">
        {{ $t('tips_modal.btn_cancel') }}
      </div>
    </div>
    <div class="give-tips-content give-tips-thanks-container" v-else>
      <img src="/hero-pics/tip.svg" @click="$emit('tipped')" alt="">
      <h3 class="give-tips-thanks" v-thematic>
        {{ $t('tips_modal.thanks') }}
      </h3>
    </div>

    <OnlinePaymentModal 
      :order_id="order._id"
      mode="tips"
      :tips_amount="tip"
      @close="online_modal = false"
      @success="delayedClose"
      v-if="online_modal"/>
  </div>
</template>

<script>
import HeartIcon from "@/components/card/HeartIcon.vue";
import PayMethods from "@/components/PayMethods.vue";

import OnlinePaymentModal from "@/components/modals/OnlinePaymentModal.vue"

import WaitersAPI from '@/api/waiters'

export default {
  props: {
    total: Number,
    tipping_methods: Object,
    isBeforeCheckout: Boolean,
    isOffline: Boolean,
    order: Object
  },
  data() {
    return {
      tip: null,
      pay_method: null,

      tipped: false,

      online_modal: false
    };
  },
  methods: {
    onConfirm() {
      if (!this.tip || (!this.isBeforeCheckout && !this.pay_method)) return;

      if (this.isOffline) {
        this.$emit('offlineTipValue', this.tip);
        this.onClose();
        return;
      }

      if (this.isBeforeCheckout) {
        this.$emit('tipValue', this.tip);
        this.giveCashTip();
        this.delayedClose();
        return;
      }
      
      if (this.pay_method == 'cash') {
        this.giveCashTip();
      } else if (this.pay_method == 'online') {
        this.online_modal = true;
      }
    },
    delayedClose() {
      this.tipped = true;
      this.online_modal = false;
      setTimeout(() => {
        this.$emit('tipped');
      }, 3000);
    },  
    giveCashTip() {
      WaitersAPI.giveCashTip(this.order._id, this.tip).then(() => {
        this.delayedClose();
      })
    },
    onClose() {
      this.$emit('close');
    }
  },
  mounted() {
    this.toggleGlobalScrolling(true);
  },
  beforeDestroy() {
    this.toggleGlobalScrolling(false);
  },
  computed: {
    tipPercent() {
      if (!this.total) return 0;

      const total = this.total;

      if (isNaN(total) || total == 0) return 0;

      return Math.round(((this.tip || 0) / total) * 100);
    },
  },
  components: {
    HeartIcon,
    PayMethods,
    OnlinePaymentModal
  },
};
</script>

<style>
.give-tips-modal {
  top: 7.7vh;
  left: 0;

  position: fixed;

  width: 100vw;
  height: calc(100vh - 7.7vh);
  background: #ededed;
  overflow-y: hidden;

  z-index: 6;
}

.give-tips-modal.dark {
  background: #000;
  color: #ededed;
}

.give-tips-content {
  padding: 2.49vh;
}

.give-tips-title {
  color: #1d1d1b;
  font-size: 2.12vh;
  text-align: center;
}

.give-tips-title.dark {
  color: #ededed;
}

.give-tips-input {
  background: #fff;
  color: #1d1d1b;
  border: 1px solid #908f8d;
  text-align: center;
  font-size: 2vh;
  width: 88%;
  display: block;
  outline: none;
  padding: 1vh 0;
  margin: 2.2vh auto 0;
  border-radius: 1vh;
}

.give-tips-input::placeholder {
  color: #908f8d;
}

.give-tips-percent {
  font-weight: 400;
  font-size: 4.5vw;
  text-align: center;
  padding: 2.5vh 0 0;
  display: block;
  color: #1d1d1b;
}

.give-tips-percent.dark {
  color: #ededed;
}

.give-tips-paymethods {
  border-top: 1px solid #a4a3a1;
  margin-top: 2.5vh;
}

.give-tips-pay-button {
  animation: fade 0.5s;
  margin: 2.5vh auto 0;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
  border-radius: 1.5vh;
  width: 100%;
  text-align: center;
  background: #129b81 !important;
  color: #ededed !important;
  padding: 1.6vh;
  cursor: pointer;
  border: 1px solid #129b81;
}

.give-tips-pay-button-disabled {
  /* background: transparent !important;
  color: #908f8d !important;
  border: 1px solid #908f8d; */
  opacity: 0.4;
}

.give-tips-back-button {
  animation: fade 0.5s;
  margin: 1.3vh auto 0;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
  border-radius: 1.5vh;
  width: 100%;
  text-align: center;
  background: transparent !important;
  color: #908f8d !important;
  padding: 1.6vh;
  cursor: pointer;
  border: 1px solid #908f8d;
}

.give-tips-thanks {
  text-align: center;
  margin-top: 1.65vh;
}

.give-tips-thanks-container {
  width: 100%;
  text-align: center;
  margin-top: 5vh;
}

.give-tips-thanks-container img {
  position: relative;
  right: -3vh;
}

.give-tips-thanks.dark {
  color: #ededed;
} 
</style>