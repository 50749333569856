<template>
  <div>
    <div v-if="firstStep">
      <div class="header">
        <h5 class="m-0 color-white mb-0" v-html="$t('roulette.leave_info')"></h5>
        <img src="../../assets/close.png" class="close-prize" @click="emitClose" alt />
      </div>

      <img src="../../assets/hi-hero.svg" class="hi">

      <input
        type="text"
        :class="isValid ? 'personal-info-input color-grey':'personal-info-input red-input'"
        maxlength="20"
        :placeholder="$t('roulette.name_ph')"
        v-model="info.name"
      />

      <input
        type="text"
        :class="isValid ? 'personal-info-input color-black':'personal-info-input red-input'"
        :placeholder="$t('roulette.phone_ph')"
        :value="info.phone"
        v-imask="phone_mask"
        @complete="updateUnmaskedPhone"
      />

      <LongButton @click="process" class="next color-white">{{ $t('roulette.next') }}</LongButton>
    </div>

    <div v-if="!firstStep">
      <div class="header">
        <h5 class="color-white mb-0" v-html="$t('roulette.approve_code')"></h5>
        <img class="close-prize" src="../../assets/close.png" @click="emitClose" />
      </div>

      <input
        type="text"
        :class="isValid ? 'color-black personal-info-input':'personal-info-input red-input'"
        placeholder="XXXX"
        v-model="confirm_code"
        inputmode="numeric"
        name="casino_code"
        pattern="[0-9]{4}"
        autocomplete="one-time-code"
      />

      <!-- :value="confirm_code"
        v-imask="code_mask"
        @complete="updateConfirmCode" -->

      <LongButton class="repeat-code" @click="onResend" v-if="canResend">
        {{ $t('roulette.resend') }}
      </LongButton>

      <LongButton @click="onConfirm" class="next">{{ $t('roulette.confirm') }}</LongButton>
    </div>
  </div>
</template>

<script>
import { IMaskDirective } from "vue-imask";

export default {
  data() {
    return {
      info: {
        name: null,
        phone: null
      },
      phone_mask: {
        mask: "{+38}-000-000-00-00",
        lazy: false
      },
      code_mask: {
        mask: "0-0-0-0",
        lazy: false
      },
      confirm_code: null,
      isValid: true,
      firstStep: true,

      canResend: false
    };
  },
  methods: {
    process() {
      if (!this.info.name || this.info.name.length < 3 || !this.info.phone) {
        this.isValid = false;
        return;
      }

      this.$emit("personal", this.info);
      this.firstStep = !this.firstStep;

      this.delayResend();
    },
    delayResend() {
      const vm = this;

      this.canResend = false;

      setTimeout(() => {
        vm.canResend = true;
      }, 30 * 1000);
    },
    onConfirm() {
      if (!this.confirm_code || this.confirm_code.length != 4) return;

      this.$emit('confirm', this.confirm_code);
    },
    onResend() {
      this.delayResend();

      this.$emit('resend');
    },
    updateUnmaskedPhone(ev) {
      const mask = ev.detail;

      this.info.phone = mask.unmaskedValue;
      this.isValid = !!this.info.name;
    },
    updateConfirmCode(ev) {
      const mask = ev.detail;

      this.confirm_code = mask.unmaskedValue;
    },
    emitClose() {
      this.$emit("close");
    },
    invalidate() {
      this.isValid = false;
      this.confirm_code = null;
    }
  },
  directives: {
    imask: IMaskDirective
  }
};
</script>

<style scoped>
.repeat-code {
  margin: 2.5vh auto 0 !important;
  background: transparent !important;
  border: 1px solid #a4a3a1 !important;
  color: #a4a3a1 !important;
}

h5 {
  display: inline-block;
  margin-top: 0.7vh !important;
  letter-spacing: 0 !important;
  font-size: 4.8vw !important;
}

h5 span {
  font-size: 4.8vw;
}

h5 span:nth-child(2) {
  font-size: 4.8vw;
}

img.hi {
  margin-left: 5.4vw;
}

img.close-prize {
  height: 4.5vh;
  width: auto;
  position: relative;
  float: right;
}

.personal-info-input {
  outline: none;
  background: #ededed;
  width: 80vw;
  border-radius: 10vw;
  text-align: center;
  display: block;
  margin: 0 auto;
  border: 1px solid #a4a3a1;
  margin-bottom: 2.3vh;
  padding: 1.8vh 0;
}


.personal-info-input:focus,
.personal-info-input:not(:focus) {
  color: #1d1d1b;
}

.red-input {
  border: 2px solid #e2223b !important;
}

.next {
  margin: 4.6vh auto 0;
}
</style>