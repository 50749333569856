import { saveAs } from 'file-saver';
import moment from 'moment';

//iPhone 5 resolution
const IMG_WIDTH = 480;
const IMG_HEIGHT = 1136;

const PADDING = 20;
const FONT_SIZE = 14;

function generateAndSaveBill(cafeName, data, locale, currency) {
  let offsetY = PADDING;

  let canvas = document.createElement('canvas');
  canvas.width = IMG_WIDTH;

  //Calculate approximate height for canvas
  let height = PADDING * 2; //padding for top and bottom
  height += FONT_SIZE * 5; //title
  height += (FONT_SIZE + 5) * 4; //waiter, date, table
  height += (FONT_SIZE + 5) * data.list.length; //list
  height += (FONT_SIZE + 5) * 7; //other stuff
  canvas.height = height;

  let ctx = canvas.getContext('2d');

  //Clear the canvas
  ctx.fillStyle = 'white';
  ctx.fillRect(0, 0, IMG_WIDTH, IMG_HEIGHT);

  //Set initial style
  ctx.font = FONT_SIZE + 'px Arial';
  ctx.fillStyle = 'black';
  ctx.strokeStyle = 'black';
  ctx.textAlign = 'center';

  //Draw title
  ctx.font = 'bold ' + FONT_SIZE + 'px Arial';
  ctx.fillText(locale.product_check, IMG_WIDTH / 2, offsetY);
  offsetY += FONT_SIZE + 5;
  ctx.fillText(cafeName, IMG_WIDTH / 2, offsetY);
  offsetY += FONT_SIZE + 5;

  const check_id = data.local_id ? data.local_id : data._id;

  ctx.fillText(locale.check_no + check_id, IMG_WIDTH / 2, offsetY);
  offsetY += FONT_SIZE * 2;

  //Draw table number
  ctx.font = '' + FONT_SIZE + 'px Arial';
  if (data.table)
    ctx.fillText(
      locale.table_no + (data.table_name || data.table),
      IMG_WIDTH / 2,
      offsetY
    );
  offsetY += FONT_SIZE + 5;

  //Draw pretty date
  ctx.fillText(
    moment(data.date).format('DD.MM.YYYY HH:mm:ss'),
    IMG_WIDTH / 2,
    offsetY
  );
  offsetY += FONT_SIZE + 5;

  //Draw waiter name
  if (data.waiter)
    ctx.fillText(`${locale.waiter} - ` + data.waiter, IMG_WIDTH / 2, offsetY);
  offsetY += FONT_SIZE + 5;

  offsetY += FONT_SIZE * 2;

  //Draw list of items in bill
  for (let i = 0; i < data.list.length; i++) {
    let item = data.list[i];

    let itemStr = item.name;

    let vStr = item.variant && item.variant !== '*' ? item.variant : '';

    let mStr = '';

    if (item.modificators) {
      mStr = item.modificators
        ? item.modificators.map((m) => m.name).join(', ')
        : '';
    }

    if (vStr && mStr) {
      itemStr = itemStr + ' (' + vStr + ' ' + mStr + ')';
    } else if (vStr && !mStr) {
      itemStr = itemStr + ' (' + vStr + ')';
    } else if (mStr && !vStr) {
      itemStr = itemStr + ' (' + mStr + ')';
    }

    let itemPrice = item.price;

    item.modificators.forEach((mod) => {
      itemPrice += mod.price;
    });

    itemStr +=
      ' x ' + item.quantity + ` ${locale.qnt}. = ` + itemPrice + ` ${currency}`;

    /*if (item.variant) {
      ctx.fillText(item.name + '(' + item.variant + ') x '+item.quantity+' шт. = ' +item.price+' грн.', IMG_WIDTH/2, offsetY);
    } else {
      ctx.fillText(item.name + ' x '+item.quantity+' шт. = ' +item.price+' грн.', IMG_WIDTH/2, offsetY);
    }*/

    ctx.fillText(itemStr, IMG_WIDTH / 2, offsetY);

    offsetY += FONT_SIZE + 5;
  }

  offsetY += FONT_SIZE + 5;

  //Draw total and discount
  ctx.font = 'bold ' + FONT_SIZE + 'px Arial';
  if (data.discount && data.discount != 0) {
    ctx.fillText(
      `${locale.discount}: ` + data.discount + '%',
      IMG_WIDTH / 2,
      offsetY
    );
    offsetY += FONT_SIZE * 2;
  }
  let pay_method_text;
  if (data.pay_method == 'cash') {
    pay_method_text = locale.cash;
  } else if (data.pay_method == 'card') {
    pay_method_text = locale.terminal;
  } else if (data.pay_method == 'online' || data.pay_method == 'liqpay') {
    pay_method_text = local.online;
  }

  if (pay_method_text) {
    ctx.fillText(
      `${locale.pay_method}: ` + pay_method_text,
      IMG_WIDTH / 2,
      offsetY
    );
    offsetY += FONT_SIZE + 5;
  }

  ctx.fillText(
    `${locale.total}: ` + data.total + ` ${currency}`,
    IMG_WIDTH / 2,
    offsetY
  );

  offsetY += (FONT_SIZE + 5) * 3;

  //Draw our ad :D
  ctx.fillStyle = '#2196F3';
  ctx.fillText('qrwaiter.com.ua', IMG_WIDTH / 2, offsetY);

  canvas.toBlob(function(blob) {
    saveAs(blob, 'bill-' + data._id + '.png');

    canvas.remove();
  });
}

export default {
  generateAndSaveBill,
};
