<template>
  <div class="support-container">
      <a href="http://t.me/QRWaiterSupportBot" target="_blank">@QRWaiter</a>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.support-container {
  text-align: center;
  margin-top: 5vh;
}

.support-container a {
  color: #416293;
  text-decoration: none;

  font-size: 1.3vh;
}

</style>