<template>
  <div>
    <h5 class="title-prize color-white">{{ $t('roulette.get_a_chance') }}</h5>
    <div class="row mt-3">
      <div class="col-3 p-0 prize" v-for="img in images">
        <img :src="img" />
      </div>
    </div>
    <div class="buttons">
      <LongButton variant="danger" @click="emitNo">{{ $t('roulette.next_time') }}</LongButton>
      <LongButton @click="emitYes">{{ $t('roulette.try') }}</LongButton>
    </div>
  </div>
</template>

<script>
export default {
  props: ["images"],
  data() {
    return {};
  },
  methods: {
    emitYes() {
        this.$emit('yes');
    },
    emitNo() {
        this.$emit('no');
    }
  },
  computed: {
   
  }
};
</script>

<style scoped>
.row {
  display: flex;
  margin: 1.5vh 0 0 0 !important;
  justify-content: center;
}
.title-prize {
 line-height: 6.5vw;
  font-family: 'Chadwick', Arial, Helvetica, sans-serif;
  font-size: 6vw;
    width: 80vw;
    display: block;
    margin: 0 auto;
    padding-top: 3.9vh;

    text-align: center;
  font-weight: 500 !important;
}

.prize {
  margin: 2.4vh 2vw 0;
}

.prize img {
  width: 23.5vw;
    height: 7.5vh;
    object-fit: cover;
    border-radius: 2vw;
}

.buttons {
  width: 100%;
  display: flex;
  font-family: 'Rubik', Arial, Helvetica, sans-serif;
  justify-content: space-evenly;
}

.buttons button {
 
  font-size: 4vw !important;
   width: 40% !important;
}

</style>