import Base from '../base'

function getTipOptions(order_id) {
    return Base.sendGet(`waiters/tips_options/${order_id}`); 
}

function giveCashTip(order_id, amount) {
    return Base.sendPost(`waiters/give_cash_tip/${order_id}`, {
        amount
    });
}

export default {
    getTipOptions,
    giveCashTip
}