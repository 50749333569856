<template>
  <div>
    <div class="casino-modal-wrapper" v-if="ready">
      <div class="hide-content"></div>

      <div
        :class="
          stage !== 'info' || stage !== 'roulette'
            ? 'casino-modal'
            : 'casino-modal no-space'
        "
      >
        <Roulette
          :prizes="prizes"
          @close="emitClose"
          @confirm="onRollConfirm"
          :prize="prize"
          ref="roulette"
          @finish="changeStage('prize')"
          v-if="stage === 'roulette'"
        />

        <div v-if="stage == 'expired'">
          <div class="expired-title color-white">
            {{ $t('roulette.limit')}}
          </div>
          <LongButton variant="red-outline" @click="emitClose" class="roll-btn">
            {{ $t("roulette.close") }}
          </LongButton>
        </div>

        <YourPrize @close="emitClose" :prize="prize" v-if="stage === 'prize'" />
      </div>
    </div>

    <PhoneConfirmationUI
      @close="onPhoneUiClosed"
      @confirm="onNewPhoneConfirmed"
      @name="onInfoConfirmed"
      ask_name
      v-if="phone_confirm_ui"
    />
  </div>
</template>

<script>
import PossiblePrizes from "./PossiblePrizes.vue";
import PersonalInfo from "./PersonalInfo.vue";
import Roulette from "./Roulette.vue";
import YourPrize from "./YourPrize.vue";

import CasinoAPI from "@/api/casino";
import TelemetryService from "@/services/telemetry";

import PhoneConfirmationUI from "@/components/modals/PhoneConfirmationUI";

export default {
  props: ["menus", "cafe"],
  data() {
    return {
      stage: "roulette",

      ready: false,

      personal_info: {
        name: null,
        phone: null,
      },

      prize: null,

      roll_id: null,

      confirmed: false,

      phone_confirm_ui: false,
    };
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
    changeStage(_stage) {
      this.stage = _stage;
    },
    onResendCode() {
      CasinoAPI.resend(this.roll_id);
    },
    onPhoneUiClosed() {
      this.phone_confirm_ui = false;
      if (!this.personal_info.phone) {
        this.emitClose();
      }
    },
    onNewPhoneConfirmed(phone) {
      this.personal_info.phone = phone;
    },
    onInfoConfirmed(name) {
      this.personal_info.name = name;

      this.$store
        .dispatch("clients/create", this.personal_info)
        .then((data) => {
          this.createRoll();
        })
        .catch((err) => {
          alert(
            `Сталась помилка підтвердження номеру телефону. Будь ласка, спробуйте пізніше.`
          );
        });
    },
    onRollConfirm(code) {
      if (this.confirmed) return;

      CasinoAPI.confirm(this.roll_id)
        .then((data) => {
          console.log(data.prize);
          this.prize = this.findProduct(data.prize);
          this.emitWin();
          this.confirmed = true;
          this.$nextTick(() => {
            this.$refs.roulette.startRolling();
          });
        });
    },
    createRoll() {
      CasinoAPI.roll(
        this.$store.state.clients.client._id,
        this.cafe._id,
        TelemetryService.getTelemetryId()
      )
        .then((data) => {
          this.roll_id = data.id;
        })
        .catch((error) => {
          if (error == "expired") {
            this.stage = "expired";
          } else {
            this.emitClose();
          }
        })
        .finally(() => {
          this.ready = true;
        });
    },
    onPersonalInfo(info) {
      this.personal_info = info;
      this.createRoll();
    },
    emitWin() {
      if (!this.prize) return;

      this.$emit("win", {
        name: this.prize.name,
        category: this.prize.category,
        product_id: this.prize._id,
        quantity: 1,
        roll_id: this.roll_id,
        price: 0,
        modificators: [],
        variants: [],
        available: true,
      });
    },
    findProduct({ menu_id, product_id }) {
      const menu = this.menus.find((m) => m._id == menu_id);

      if (!menu) return null;

      return menu.products.find((p) => p._id == product_id);
    },
    checkClient() {
      if (!this.$store.getters["clients/isAuthorized"]) {
        this.phone_confirm_ui = true;
      } else {
        this.personal_info.phone = this.$store.state.clients.client.phone;
        this.personal_info.name = this.$store.state.clients.client.name;
        this.createRoll();
      }
    },
  },
  computed: {
    prizes() {
      return this.cafe.roulette.prizes
        .sort((a, b) => a.chance - b.chance)
        .map((pr) => this.findProduct(pr))
        .filter((pr) => !!pr);
    },
    prizesImages() {
      return this.prizes.map((p) => p.image);
    },
  },
  components: {
    PossiblePrizes,
    PersonalInfo,
    Roulette,
    YourPrize,
    PhoneConfirmationUI,
  },
  mounted() {
    this.resetScroll();
    this.toggleGlobalScrolling(true);

    this.checkClient();
  },
  beforeDestroy() {
    this.toggleGlobalScrolling(false);
  },
};
</script>

<style scoped>
.hide-content {
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: hidden;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.4);
  opacity: 1;
  top: 0;
  z-index: 10;
  left: 0;
}

.casino-modal {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 90vw;
  background-color: #1d1d1b;
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 2.3vh 2.3vh 10vw 10vw;
  position: fixed;
  padding: 0 0 4.7vh;

  height: auto;
  z-index: 11;

  z-index: 10001;
}

.casino-modal.no-space {
  padding: 3.9vh 0 4.6vh 0;
}

.expired-title {
  text-align: center;
  padding: 0 3vh;
  font-size: 2vh;
  margin-top: 4.7vh;
}
</style>