<template>
  <div class="wrapper">
    <OrderLoader v-if="loading" />

    <div
      class="neworder"
      v-if="!loading && !error"
    >
      <!-- <GreetingPage
        :cafe="cafe"
        :target="target"
        :subscription="subscription"
        :active_order="active_order"
        @makeOrder="onMakeOrderPressed"
        @acceptActiveOrder="onActiveOrderAccept"
        @cancelActiveOrder="onActiveOrderCancel"
        v-if="!isMakingOrder"
      /> -->

      <MainUI
        ref="mainUi"
        :cafe="cafe"
        :menus="menus"
        @switchView="onSwitchView"
        :target="target"
        :client="client"
        :requestId="request_id"
        :active_order="active_order"
        @acceptActiveOrder="onActiveOrderAccept"
        @cancelActiveOrder="onActiveOrderCancel"
        @incrementRequestId="incrementRequestId"
        @showMotdModal="show_motd_modal = true"
      />
    </div>

    <LoadError
      :error="error"
      v-if="error"
    />

    <OrderModalTemplate
      :titleMode="cafe.ui_button_title || 'INSIDE'"
      :modePicture="'/hero-pics/choose-system.svg'"
      :modeBgColor="'#ededed'"
      :motd="cafe.motd"
      :cafe="cafe"
      :showBuiltinTitle="false"
      @close="show_motd_modal = false"
      v-if="show_motd_modal"
    />
  </div>
</template>

<style scoped>
</style>

<script>
import GreetingPage from '@/components/GreetingPage.vue'
import OrderLoader from '@/components/OrderLoader.vue'
import LoadError from '@/components/LoadError.vue'

import OrderModalTemplate from '@/components/modals/OrderModalTemplate.vue'

import MainUI from '@/components/MainUI.vue'

import OrderAPI from '@/api/order'
import AnalyticsAPI from '@/api/analytics'

import OrderSaver from '@/services/order/saver.js'

import Geolocation from '@/services/geolocation'

import Polyglote from '@/services/polyglote'

import TelemetryService from '@/services/telemetry'

import FacebookHelper from '@/services/facebook/helper.js'

import PositionSaver from '@/services/positionsaver'

import ThemeService from '@/services/theme/service'

import moment from 'moment'

export default {
  data() {
    return {
      showOM: false,
      showGP: true,

      loading: true,
      error: null,

      isMakingOrder: false,

      cafe: {},
      menus: [],
      currentMenuIndex: 0,
      lastUiView: 'menu',

      target: {
        cafe_id: null,
        section: null,
        table: null
      },
      request_id: null,
      subscription: true,
      active_order: null,
      client: null,

      show_motd_modal: false,

      try_all_locales: false
    }
  },
  methods: {
    fetchData() {
      let code = this.$route.params.code

      const decoded = atob(code)

      if (decoded.startsWith('246/') || decoded.startsWith('281/')) {
        const tokens = decoded.split('/')

        const cafe_id = tokens[0]
        const section = tokens[1]
        const table = tokens[2]

        if (cafe_id == '246' && section == '1') {
          this.$router.replace({
            name: 'hotel_room',
            params: {
              code: btoa(`281/0/${table}`)
            }
          })
          return
        }

        this.$router.replace({
          name: 'hotel_room',
          params: {
            code
          }
        })
        return
      }

      OrderAPI.prepare(code)
        .then(data => {
          if (data.can_view_cafe_page && data.alternative_link) {
            this.$router.push(`/direct_menu/${data.alternative_link}`)
            return
          }

          this.cafe = data.cafe
          this.menus = data.menus
          this.target = data.target
          this.request_id = data.request_id
          this.subscription = data.subscription
          //this.subscription = true;
          this.active_order = data.active_order

          document.title = `${this.cafe.name} - QRWaiter Inside`

          if (this.cafe.ui_settings && this.cafe.ui_settings.use_inside_superapp) {
            window.location.href = `https://new.qrwaiter.com.ua/inside/${code}`
            return
          }

          TelemetryService.init(this.target.cafe_id, 'inside', 'mobile_web')
          TelemetryService.emit(TelemetryService.Events.OpenPage, window.location.href)

          this.loading = false

          this.$store.commit('menu/setMenus', this.menus)
          this.$store.dispatch('menu/buildAllTranslations', [this.$i18n.locale, ...Polyglote.getAllLocales()])
          this.$store.commit('menu/addBuiltinTranslations', this.$i18n.locale)
          // if (this.try_all_locales) {
          //   this.$store.dispatch("menu/tryLocales", [
          //     Polyglote.getAllLocales()
          //   ]);
          // } else {
          //   this.$store.dispatch("menu/tryLocale", Polyglote.getSavedLocale());
          // }

          // this.$store.commit("menu/buildDictionaries");

          this.$store.dispatch('currency/fetch', this.cafe.currency_id)
          this.$store.dispatch('worldwide/init', data.country)

          this.$store.commit('currency/setCanRoundPrices', this.cafe.round_menu_prices)

          this.$store.dispatch('cart/init', {
            cafe_id: this.target.cafe_id,
            type: 'inside'
          })

          this.checkAppClientToken()

          if (this.cafe.ui_settings && this.cafe.ui_settings.locale_override) {
            this.$i18n.locale = this.cafe.ui_settings.locale_override
          }

          if (data.country == 'UA' && this.$i18n.locale == 'ru') {
            this.$i18n.locale = 'uk' //fuck russia, force to learn Ukranian
          }

          if (this.cafe.ui_settings && this.cafe.ui_settings.theme_override) {
            ThemeService.setGlobalOverride(this.cafe.ui_settings.theme_override)
          }

          this.$store
            .dispatch('clients/init')
            .then(() => {
              this.$store.dispatch('clients/fetchBalance', {
                cafe_id: this.target.cafe_id
              })
            })
            .finally(() => {
              let platform = 'web'

              if (this.$route.query.app_client_id) {
                const ua = window.navigator.userAgent || ''

                if (ua.toLowerCase().includes('android')) {
                  platform = 'android_app'
                }

                if (ua.toLowerCase().includes('iphone') || ua.toLowerCase().includes('safari')) {
                  platform = 'ios_app'
                }
              }

              AnalyticsAPI.registerView({
                cafe_id: this.target.cafe_id,
                mode: 'inside',
                client_id: this.$store.state.clients.client_id,
                platform,
                from_marketplace: false,
                referer: document.referrer
              }).catch(error => {
                console.error(`Analytics registerView() failed: ${error}`)
              })
            })

          FacebookHelper.processCafePixel(this.cafe.facebook_pixel_id)

          this.$nextTick(this.checkSavedPosition)
        })
        .catch(error => {
          this.error = error
          this.loading = false
          if (error === 'cafe_not_paid' && code.startsWith('MTY4')) {
            this.error = 'Система QRWaiter не оплачена'
          }

          this.loading = false
          console.error(error)
        })
    },

    checkAppClientToken() {
      if (this.$route.query.app_client_id) {
        const client_id = this.$route.query.app_client_id

        this.$store.dispatch('clients/setClientId', client_id)
      }
    },

    checkOrderSave() {
      if (OrderSaver.isActual(this.target.cafe_id)) {
        this.isMakingOrder = true
      }
    },

    //UI
    onMakeOrderPressed(_client) {
      this.isMakingOrder = true

      this.client = _client
    },

    onActiveOrderAccept() {
      OrderSaver.save(this.active_order)
    },

    onActiveOrderCancel() {
      this.active_order = null
    },

    reload() {
      window.location.reload()
    },

    incrementRequestId() {
      this.request_id = `${Date.now()}`
    },

    checkSavedPosition() {
      const cache = PositionSaver.load('inside')

      //If we have cached position
      if (cache) {
        if (cache.cafe_id != this.cafe._id) {
          PositionSaver.clear('inside')
          return
        }

        //Restore the scroll position
        if (!this.active_order) PositionSaver.apply(cache.scroll_pos)

        //Switch menu if needed
        if (cache.menu_index < this.menus.length) {
          this.currentMenuIndex = cache.menu_index
        }

        //Change the view
        if (cache.view == 'cart' && this.cart.length) {
          this.$refs.mainUi.onSwitchView(cache.view)
          this.toggleGlobalScrolling(false)
        }
      }

      window.addEventListener('scroll', this.onPageScroll)
    },
    onPageScroll() {
      //Save our scroll, view, menu and cafe_id to local storage

      const scroll_pos = window.document.documentElement.scrollTop
      const view = this.lastUiView
      const menu_index = this.currentMenuIndex
      const cafe_id = this.cafe._id

      PositionSaver.save('inside', {
        cafe_id,
        scroll_pos,
        view,
        menu_index
      })
    },

    onSwitchView(v) {
      this.lastUiView = v
      this.onPageScroll()
    }
  },
  computed: {
    currentMenu() {
      return this.menus[this.currentMenuIndex]
    },
    cart() {
      return this.$store.state.cart.items
    }
  },
  mounted() {
    this.fetchData()

    Geolocation.clearPermissionCache()
  },
  components: {
    GreetingPage,
    MainUI,
    OrderLoader,
    LoadError,
    OrderModalTemplate
  }
}
</script>
