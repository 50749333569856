<template>
  <div>
    <div class="hide-content"></div>
    <div class="cancel-modal-wrapper">
      <div class="cancel-modal">
        <p class="c-red">{{ $t("cancel.confirm_text") }} <template v-if="timeLeft">({{timeLeft}})</template></p>
        <h4>{{ $t("cancel.select_reason") }}</h4>
        <CircleToggle
          :value="reason == $t('cancel.reasons.wrong_order')"
          @input="reason = $t('cancel.reasons.wrong_order')"
          class="mt-10"
          >{{ $t("cancel.reasons.wrong_order") }}</CircleToggle
        >
        <CircleToggle
          :value="reason == $t('cancel.reasons.no_time')"
          @input="reason = $t('cancel.reasons.no_time')"
          class="mt-10"
          >{{ $t("cancel.reasons.no_time") }}</CircleToggle
        >
        <CircleToggle
          :value="reason == '$'"
          @input="reason = '$'"
          class="mt-10"
          >{{ $t("cancel.reasons.custom") }}</CircleToggle
        >
        <textarea
          v-model="customReason"
          rows="3"
          :placeholder="$t('cancel.enter_reason')"
          v-if="reason == '$'"
        ></textarea>
      </div>
      <div class="action-btns">
        <button class="btn btn-cancel color-white" @click="cancel">
          {{ $t("cancel.button_cancel") }}
        </button>
        <button class="btn btn-next color-white" @click="confirmAction">
          {{ $t("cancel.button_continue") }}
        </button>
      </div>
    </div>
  </div>
</template>


<style scoped>
.cancel-modal-wrapper {
  position: absolute;
  z-index: 5;
  margin: 0 auto;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cancel-modal {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 1.5vh;
  padding: 2.5vh 5.4vw 3.7vh;
  background: #1d1d1b;
  width: 90%;

  margin: 0 auto;
  display: block;
}

.mt-10 {
  margin-top: 1.2vh;
}

.c-red {
  color: #e2223b;
}

.color-white {
  color: #ededed;
}

.custom-control-label {
  font-size: 4.5vw;
}

.hide-content {
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5px);
  top: 0;
  z-index: 2;
  left: 0;
}

.action-btns {
  display: flex;
  margin: 2.5vh auto 0;
  width: 90%;
  justify-content: space-between;
}

.btn-cancel {
  background: #e2223b;
}

.btn-next {
  background: #129b81;
}

p {
  color: #a4a3a1;
  font-size: 5vw;
  text-align: center;
  line-height: normal;
  margin-bottom: 0;
}

h4 {
  font-size: 2.5vh;
  padding: 2.5vh 0 1.2vh;
  margin-bottom: 0;
  color: #ededed;
}

.action-btns button {
  border-radius: 1.5vh;
  font-weight: 500;
  box-shadow: 0px 4px 1px rgba(0, 0, 0, 0.25);
  font-size: 4.5vw;
  padding: 1.5vh 3vh;
  min-width: 47%;
}

textarea {
  border-radius: 10px;
  border: 1px solid #908f8d;
  outline: none;
  background: #fff;
  padding: 0.5vh 2vw;
  color: #908f8d;
  width: 100%;
  margin-top: 2.2vh;
  transition: 0.3s;
  display: block;
}

textarea:active,
textarea:hover {
  border: 1px solid darkgray;
}
</style>

<script>
import CircleToggle from "@/components/CircleToggle.vue";

export default {
  data() {
    return {
      reason: null,
      customReason: null,
      option: "",
    };
  },
  components: {
    CircleToggle,
  },
  props: {
    timeLeft: Number
  },
  methods: {
    confirmAction() {
      if (!this.reason) return;

      if (this.reason === "$") {
        this.reason = this.customReason;
      }
      this.$emit("confirm", this.reason);
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>