<template>
  <div class="container">
    <ul>
      <li v-thematic v-for="(split,idx) in splits" :class="{
        paid: true,
        'no-comment': hideComment
      }" :key="idx">
        <img :src="split.paid_at ? '/icons/selected.svg' : '/icons/unselected.svg'" class="icon" />
        <span class="bill">{{idx+1}} {{ $t('split_payment.number') }}</span>
        <span class="bill-price">
            {{currency.symbol}}{{split.total}}
        </span>
      </li>
      <!-- <li>
        <img src="/icons/unselected.svg" class="icon" />
        <span class="bill">1 рахунок</span>
        <span class="bill-price">₴99999</span>
      </li> -->
    </ul>
    <p class="info" v-if="!hideComment">
      {{ $t('split_payment.sent_text') }}
    </p>
  </div>
</template>

<script>
export default {
    props: {
        splits: Array,
        index: Number,
        hideComment: {
          type: Boolean,
          default: false
        }
    },
    computed: {
        currency() {
            return this.$store.state.currency.current;
        },
        nextIndex() {
          return this.splits.findIndex(s => !s.paid_at);
        }
    }
}
</script>

<style scoped>
.container {
  background: transparent;
  border: 1px solid #908f8d;
  border-radius: 1.2vh;
  width: 100%;
  padding: 2vh;
  margin-top: 2.5vh;
}
p.info {
  text-align: center;
  line-height: normal;
  margin: 0;
  color: #908f8d;
  font-size: 2vh;
  padding-top: 1.2vh;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
li {
  display: flex;
  justify-content: space-between;
  color: #908f8d;
  padding: 1vh 0;
  border-bottom: 1px solid #908f8d;
}
li:first-child {
  padding-top: 0;
}
li.paid {
  color: #1d1d1d;
}
li.paid.dark {
  color: #ededed;
}
img.icon {
  height: 2.8vh;
  width: auto;
}
span.bill {
  width: 55%;
}
span.bill-price {
  width: 30%;
  text-align: right;
}
.paid.no-comment:last-of-type {
  border-bottom: 0;
  padding-bottom: 0;
}
</style>