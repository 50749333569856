<template>
  <div>
      <h3 class="color-white">{{ $t('roulette.your_prize') }}</h3>

      <div class="prize">
          <img class="prize-image" :src="prize ? prize.image : '/icons/no-prize.jpg'">
          <div class="row" v-if="prize">
              <div class="col-9 text-left color-white">
                  {{ prize.name }}
              </div>
              <div class="col-3 text-right color-white">
                  {{ prizePriceValue }}{{ currency.symbol}}
              </div>
          </div>

          <div class="row" v-if="!prize">
              <div class="col-12 text-center color-white">
                  {{ $t('roulette.no_prize') }}
              </div>
          </div>
      </div>

      <div class="prize-text color-grey" v-if="prize" v-html="$t('roulette.will_be_added')">
          
      </div>

      <div class="prize-text color-grey" v-if="!prize" v-html="$t('roulette.you_lost')"></div>

      <LongButton @click="emitClose" class="take-prize">
          {{ prize ? $t('roulette.take_prize') : $t('roulette.close') }}
      </LongButton>
  </div>
</template>

<script>
export default {
    props: {
        prize: Object
    },
    data() {
        return {

        }
    },
    methods: {
        emitClose() {
            this.$emit('close');
        }
    },
    computed: {
        currency() {
            return this.$store.state.currency.current;
        },
        prizePriceValue() {
            let ttl = this.prize.base_price || 0;

            ttl += this.prize.price_groups.filter(pg => pg.single_select).reduce((acc,it) => {
                return acc + it.items[0].price; 
            }, 0);

            return ttl;
        }
    }
}
</script>

<style scoped>
.prize-text {
    font-size: 4vw;
    padding-top: 2.3vh;
    text-align: center;
}

.row {
    margin: 1.5vh 0 0 0 !important;
}

.col-9 {
    font-size: 5.5vw;
    padding-left: 6vw;
     padding-top: 0.8vh;
}
.col-3 {
    padding-right:6vw;
    font-weight: 100;
    font-size: 7vw;
}

img.prize-image {
    width: 80vw;
    border-radius: 15px;
    height: 26vh;
    object-fit: cover;
}

h3 {
    padding-top:3.9vh;
  font-size: 8vw;
  margin-bottom: 0;
  text-align: center;
    
}

.take-prize {
  margin: 4.6vh auto 0;
 
}

.prize {
    text-align: center;
    margin: 3.1vh 2vw 0;
}
</style>