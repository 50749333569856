<template>
  <div class="modal-wrapper">
    <div class="hide-content"></div>
    <div class="active-order-modal">
      <h4 class="color-white">{{ $t('active_order.modal_text') }}</h4>
      <CompleteOrderCard :cafe="cafe" class="hide-shadow" :order="order" />
       
      <div class="action-btns">
        <button class="btn-cancel rect-borders" @click="emitCancel">{{ $t('active_order.cancel') }}</button>
        <button class="btn-next rect-borders" @click="emitAccept">{{ $t('active_order.accept') }}</button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.action-btns {
  display: flex;
  width: 90vw;
  margin: 0 auto;
  justify-content: space-between;
}

/* .hide-shadow {
  box-shadow: none !important;
} */

.action-btns button {
  border: none;
  padding: 1.6vh 2vh;
  font-size: 2vh;
  min-width: 47%;
  font-weight: 500;
  border-radius: 5vh;
  box-shadow: 0px 4px 1px rgba(0, 0, 0, 0.25);
  color: white;
}

.btn-cancel {
  background: #e2223b;
}

.btn-next {
  background: #129b81;
}

@keyframes active-order {
  0% {
    left: -50px;
    background: #545454;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.active-order-modal {
  position: absolute;
  background: transparent;
  z-index: 11;
  top: 50%;
  transform: translateY(-50%);
  padding: 3vh 0;
  height: 75vh;
  overflow: auto;
  width: 100%;
  left: 0;
  margin: 0 auto;
  right: 0;
}

.active-order-modal h4 {
  text-align: center;
  line-height: normal;
  width: 90%;
  padding-bottom: 2vh;
  margin: 0 auto;
  font-size: 2.5vh;
}
/* 
.active-order span {
  background: #262626;
  padding: 7px 25px 0 18px;
  margin: 8px 0 8px 6px;
  border-radius: 50px;
  font-style: italic;
  font-weight: bold;
  font-size: 24px;
  color: white;
} */

.hide-content {
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: hidden;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.4);
  opacity: 1;
  z-index: 10;
  top: 0;
  left: 0;
}
</style>

<script>
import CompleteOrderCard from "@/components/card/CompleteOrderCard.vue";

export default {
  props: {
    order: Object,
    cafe: Object
  },
  methods: {
    emitCancel() {
      this.$emit("cancel");
    },
    emitAccept() {
      this.$emit("accept");
    },
  },
  mounted() {
    document.body.style.overflowY = "hidden";
    // this.toggleGlobalScrolling(true);
  },
  beforeDestroy() {
    document.body.style.overflowY = "auto";
    // this.toggleGlobalScrolling(false);
  },
  components: {
    CompleteOrderCard,
  },
};
</script>