import { Howl, Howler} from 'howler';

let orderUpdateSound = null;

function init() {
    orderUpdateSound = new Howl({
        src: ['/sounds/order_update.ogg']
    });
}

function playOrderUpdateSound() {
    orderUpdateSound.play();
}

export default {
    init,
    playOrderUpdateSound
}