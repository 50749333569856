<template>
  <div class="tips">
    <p class="color-grey">{{ $t("pay.support_text") }}</p>
    <div class="like-block" @click="onHeartClicked">
      <img src="/icons/heart-block.png" class="likes" alt />
      <img src="/icons/like.svg" alt="like" class="like" :style="heartStyle" />
    </div>
    <b>+ {{ selectedTipPercent || 0 }}% {{ $t("pay.tip_amount") }}</b>
    <!-- <div class="percentage-blocks">
      <div :class="getTipClass(percent)" @click="onTipSelect(percent)" v-for="percent in percentages" :key="percent">
        {{percent}}%
      </div>
      
    </div> -->

    <!-- <p class="color-grey" v-if="hasRecommended"></p> -->

    <input
      type="number"
      v-model.number="tip"
      min="0"
      maxlength="9"
      class="tip-summ"
      :placeholder="$t('pay.choose_tip_summ')"
    />

    <!-- <div class="tips-switcher">
      <div
        @click="chooseOptionTip(1)"
        :class="selectedTip == 1 ? 'active' : ''"
        class="tip-select add-2-cheque"
      >
        {{ $t("pay.tip_add_to_cheque") }}
      </div>
      <div
        @click="chooseOptionTip(2)"
        :class="selectedTip == 2 ? 'active' : ''"
        class="tip-select add-separate"
      >
        {{ $t("pay.separate") }}
      </div>
    </div> -->

    <!-- <LongButton 
        class="mt"
        variant="success" 
        @click="onPayClick"
    >{{$t('pay.choose_pay_method')}}</LongButton>-->
  </div>
</template>

<style scoped>
.tips {
  background: #ededed;
  margin-top: -2vh;
  border-radius: 0 0 2.5vh 2.5vh;
  padding-bottom: 3.7vh;
}

.tips p {
  text-align: center;
  font-size: 4vw;
  padding: 2.4vh 4.5vw 0;
  margin-bottom: 0;
}

input.tip-summ {
  background: #fff;
  color: #000000;
  border: 1px solid #908f8d;
  text-align: center;
  font-size: 2vh;
  width: 88%;
  display: block;
  outline: none;
  padding: 1vh 0;
  margin: 2.2vh auto 0;
  border-radius: 1vh;
}

.tips-switcher {
  display: flex;
  width: 90%;
  margin: 2.2vh auto 0;
  border-radius: 0.8vh;
  background: #e5e5e5;
}

.tip-select {
  width: inherit;
  text-align: center;
  font-size: 1.8vh;
  color: #908f8d;
  border-radius: 0.8vh;
  padding: 0.8vh 0;
}

.tip-select.active {
  background: #1d1d1d;
  color: #ededed;
}

.like-block {
  position: relative;
  left: 0;
  top: 0;
  margin-top: 3.7vh;
}

.mt {
  margin-top: 3.7vh;
}

.likes {
  position: relative;
  width: auto;
  height: 21.6vh;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 auto;
  display: inline-block;
}

.like {
  position: absolute;
  transition: 0.3s;
  left: 0;
  height: 14vh;
  right: 0px;
  margin: 5.8vh auto 0;
  width: auto;
}

.percentage-blocks {
  display: flex;
  padding-top: 3.7vh;
  justify-content: space-evenly;
}

.tip {
  box-shadow: 0px 1px 7px #dadada;
  border-radius: 3vw;
  padding: 2vh 0px;
  flex-basis: 15%;
  text-align: center;
  font-weight: bold;
  font-size: 5vw;
  color: #545454;
  transition: 0.3s;
}

.tip-active {
  border: 2px solid #129b81;
  padding: 1.5vh 0;
  margin-top: -1vh;
  margin-bottom: 1vh;
}

b {
  font-weight: 400;
  font-size: 4.5vw;
  text-align: center;
  padding: 2.5vh 0 0;
  display: block;
}
</style>

<script>
import moment from "moment";

const HeartStyles = {
  "0%": {
    display: "none",
  },
  "-%": {
    height: "9.2vh",
  },
  "5%": {
    "margin-top": "3.2vh",
    height: "14vh",
  },
  "10%": {
    height: "19.5vh",
    "margin-top": "0.3vh",
  },
  "15%": {
    "margin-top": "-3vh",
    height: "25vh",
  },
};

export default {
  data() {
    return {
      // heartStyle: HeartStyles["0%"],

      tip: 0,
      // selectedTipPercent: 0,

      hasRecommended: false,

      percentages: [0, 5, 10, 15],
      selectedTip: 1,
    };
  },
  props: {
    order: Object,
    cafe: Object,
  },
  watch: {
    tip() {
      this.$emit('tip', Math.abs(this.tip || 0));
    }
  },
  methods: {
    onTipSelect(_percent) {
      let percent = _percent;
      if (this.selectedTipPercent == _percent) return;

      let total = this.order.total;

      //prevent double tipping
      if (this.order.tip_percent) {
        console.log(`has tip ${this.order.tip_percent}`);
        total = Math.ceil((total * (100 - this.order.tip_percent)) / 100);
      }

      this.tip = Math.ceil(total * (percent / 100));

      this.selectedTipPercent = percent;

      this.heartStyle = HeartStyles[`${percent}%`];

      this.$emit("tip", this.tip);
    },
    onHeartClicked() {
      const index = this.percentages.indexOf(this.selectedTipPercent);

      let new_percent = 0;

      if (index == this.percentages.length - 1) {
        new_percent = this.percentages[0];
      } else {
        new_percent = this.percentages[index + 1];
      }

      this.onTipSelect(new_percent);
    },
    onPayClick() {
      this.$emit("pay", this.tip);
    },
    getTipClass(_percent) {
      return {
        tip: true,
        "tip-active": this.selectedTipPercent === _percent,
      };
    },
    chooseOptionTip(tip) {
      this.selectedTip = tip;
    },
  },
  computed: {
    currency() {
      return this.$store.state.currency.current;
    },
    selectedTipPercent() {
      return Math.round(
        this.tip / this.order.total * 100
      );
    },
    heartStyle() {
      if (!this.tip) return HeartStyles['0%'];

      let max_style = 0;

      for (const p of this.percentages) {
        if (this.selectedTipPercent >= p) max_style = p;
      }

      return HeartStyles[`${max_style}%`] || HeartStyles['0%'];
    }
  },
  mounted() {
    if (this.cafe.serving_fee.percent && this.cafe.serving_fee.modes.inside) {
      return;
    }

    // const diff = moment(this.order.processed_at).diff(
    //   moment(this.order.date),
    //   "seconds"
    // );

    // if (diff <= 4 * 60) {
    //   this.onTipSelect(5);
    //   this.hasRecommended = true;
    // }

    // if (diff <= 60) {
    //   this.onTipSelect(10);
    // }
  },
};
</script>