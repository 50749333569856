<template>
  <div>
    <div class="order-bill" v-thematic>
      <InsideCardHeader :order="order" :isHotel="isHotel"/>

      <!-- <CardHeader :order="order" :target="target" :number="1" /> -->

      <div
        :class="isOrderDone ? 'order-done-block color-white':'order-paying-block color-black'"
        v-if="order.status === 'executed' || order.status === 'paying'"
      >
        <div class="order-details">
           
             <div class="mt-0 pt-0">
              <img src="/icons/date-white.svg" />
               <p class="float-right">{{ orderDate }}</p>
              <!-- {{ $t('bill.date') }} -->
             </div>
            <div>
              <img src="/icons/time-white.svg" />
                <p class="float-right">{{ orderServingTime }}</p>
              <!-- {{ $t('bill.serving_time') }} -->
            </div>
            <div>
              <img src="/icons/reservation.svg" />
               <p class="float-right">{{ order.table_name || order.table }}</p>
              <!-- {{ $t('bill.table') }} -->
            </div>
           
          
        </div>
      </div>

      <InsideStatusBar :order="order" :index="0" />

      <CardList :list="joinedList" :status="order.status" :billTotal="order.total" />

      <div class="total-block">
        <h4 class="total">{{ $t('list.total')}}</h4>
        <span class="amount">{{order.total | formatMoney}}{{currency.symbol}}</span>
      </div>

      <div class="bonus-balance" v-if="order.bonuses_change">
        <span class="bonus-text">{{ $t('pay_methods.bonus_balance') }}</span>
        <span :class="{
          'bonus-value': true,
          'bonus-value-plus': order.bonuses_change > 0,
          'bonus-value-minus': order.bonuses_change < 0 
        }">
          {{ order.bonuses_change > 0 ? '+' : ''}}
        {{ order.bonuses_change }}{{currency.symbol}}</span>
      </div>

      <div class="lower-bill-block" v-if="(order.status === 'executed' || order.status === 'paying') && order.discount">
        <p v-if="order.discount">
          {{ $t('bill.discount') }}
          <span>{{ order.discount || 0 }}%</span>
        </p>
        <!-- <p>
          {{ $t('order_buttons.tips') }}
          <span>{{order.tip_percent || 0}}%</span>
        </p> -->
      </div>

      <LongButton @click="downloadBill" class="btn-transparent">{{ $t('bill.download_check') }}</LongButton>
    </div>
   
  </div>
</template>

<style scoped>
.order-bill,
.partner-card {
  width: 90vw;
  background: #ededed;
  margin: 0 auto;
  padding-bottom: 3.7vh;
  display: block;
  box-shadow: 0px 0px 40px #dddddd;
  border-radius: 4vh;
}

.order-bill.dark {
  box-shadow: none;
}

.order-details {
  padding: 2.5vh 5.5vw 2.5vh;
}

.order-details div {
  padding-top: 1.9vh;
}
p.float-right {
  margin-bottom: 0;
}

.btn-transparent {
  background: transparent;
  border: 1px solid #1d1d1b;
  box-shadow: none;
  color: #1d1d1b;
}

.corners {
  border-radius: 0 0 3vh 3vh;
}

h5 {
  line-height: 0.8;
  display: inline;
  margin: 0 !important;
  font-size: 5.4vw !important;
}

.total-block {
  padding: 2.5vh 5.4vw 0;
  background: #ededed;
}

img.soc-icon {
  height: 30px !important;
  left: 60px !important;
  margin-top: -2px !important;
  display: inline !important;
  position: absolute;
}

.primary-viber {
  background: rgb(167, 16, 212);
}

.order-done-block,
.order-paying-block {
  color: #ededed;
  width: 100%;
  background: #1d1d1b;
  margin: 0;
}

.order-paying-block {
  background: #1d1d1b;
}

.color-white {
  color: #ededed;
}

 

.order-done-block img {
  margin-top: -5px;
  height: 2vh;
  width: 3vh;
}

.partner-title {
  padding: 30px 0 20px 20px;
}

.partner-card {
  box-shadow: 0px 10px 14px #f2f2f2 !important;
}

.partner-card img {
  height: 120px;
  display: block;
  margin: 0 auto;
}

.lower-bill-block {
  padding: 1.2vh 5.4vw 0;
  background: #ededed;
}

.lower-bill-block p {
  margin: 0.6vh 0 0 0;
}

p.id {
  display: inline;
  float: right;
  margin-top: 0.6vh !important;
  line-height: 0.8;
  font-size: 5.4vw;
}

.lower-bill-block span {
  float: right;
}

b {
  font-size: 18px;
  text-align: center;
  padding: 10px 0;
  display: block;
}

.row {
  margin-top: 0 !important;
  display: flex;
  width: 100%;
  margin: 0 !important;
}

.row p {
  font-weight: 500;
  margin-top: 1.2vh;
  margin-bottom: 0;
  line-height: 1.9;
  font-size: 4.3vw;
}

.total {
  font-weight: bold;
  display: inline-block;
  margin: 0 !important;
  font-size: 6.5vw;
  text-align: initial;
  padding-top: 0.9vh;
  line-height: 0.8;
}

span.amount {
  float: right;
  margin-top: 0vh;
  line-height: 0.8;
  font-size: 9.6vw;
  font-weight: 500;
}

.bonus-balance {
  padding: 1.2vh 5.4vw 1.2vh;
  border-bottom: 1px solid #BFBFBF;
  font-size: 2vh;
}

.bonus-balance span {
color: #908F8D;
text-transform: uppercase;
}
span.bonus-value {
  float: right;
  display: block;
  line-height: normal;
  color: #129B81;
  font-size: 2.2vh;
}

.bonus-value-minus {
  color: #e2223b !important;
}
</style>

<script>
import CardHeader from "@/components/card/CardHeader.vue";
import CardList from "@/components/card/CardList.vue";

import InsideCardHeader from "@/components/card/InsideCardHeader.vue";
import InsideStatusBar from "@/components/card/InsideStatusBar.vue";

import BillSaver from "@/services/billsaver";

import moment from "moment";

export default {
  props: {
    order: Object,
    cafe: Object,
    target: Object,
  },
  methods: {
    downloadBill() {
      const locale = this.$i18n.messages[this.$i18n.locale].download_bill;
      const currency = this.$store.state.currency.current.code;
      BillSaver.generateAndSaveBill(
        this.cafe.name,
        this.order,
        locale,
        currency
      );
    },
  },
  computed: {
    joinedList() {
      let jl = this.order.list;

      // if (this.order.appends) {
      //   this.order.appends.forEach((a) => (jl = jl.concat(a.list)));
      // }

      return jl;
    },
    isOrderDone() {
      return this.order.status == "executed";
    },
    orderDate() {
      return moment(this.order.date).format("DD/MM/YY");
    },
    orderServingTime() {
      return moment.duration(moment().diff(this.order.date)).format("hh:mm:ss");
    },
    payMethodText() {
      if (this.order.pay_method === "cash") return this.$t("bill.cash");
      if (this.order.pay_method === "card") return this.$t("bill.card");
      if (this.order.pay_method === "liqpay") return this.$t("bill.online");

      return "?";
    },
    currency() {
      return this.$store.state.currency.current;
    },
    isHotel() {
      return this.cafe.primary_category == 'hotel';
    }
  },
  components: {
    CardHeader,
    CardList,
    InsideStatusBar,
    InsideCardHeader,
  },
};
</script>