<template>
  <div>
    <div class="hide-content"></div>
    <div class="not-paid">
      <h5>{{ $t(`not_paid_modal.title`) }}</h5>
      <img src="../assets/teapot.png" alt />
      <p class="error-msg">
          {{ $t('not_paid_modal.text') }}
      </p>
      <LongButton variant="primary" @click="emitClose">OK</LongButton>
    </div>
  </div>
</template>

<script>
import LongButton from "@/components/LongButton";

export default {
  data() {
    return {

    };
  },
  props: {
      error: {
          type: String,
          default: null
      }
  },
  methods: {
      emitClose() {
          this.$emit('close');
      }
  },
  components: {
    LongButton
  }
};
</script>

<style scoped>
.not-paid {
  box-shadow: 0px 0px 5px #dddddd;
  border-radius: 40px;
  display: block;
  background: white;
  margin: 0 auto;
  width: 90%;
  text-align: center;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  padding: 20px 0 25px;
  position: absolute;
}

.not-paid img {
  height: 165px;
}

p {
  padding-top: 10px;
}

.error-msg {
  padding-left: 10px;
  padding-right: 10px;
}

.hide-content {
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: hidden;
  background: #868686;
  opacity: 0.9;
  top: 0;
  left: 0;
}
</style>