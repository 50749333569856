<template>
  <div>
    <div class="hide-content"></div>
    <div class="error-modal">
      <div class="general-popup">

        <img src="/hero-pics/sad.svg" class="hero" />
        <h3>
            {{ $t('order_not_accepted.title') }}
        </h3>

        <!-- <p class="error-msg" v-html="" v-if="!primary"></p> -->
      </div>
      <LongButton
        variant="primary"
        class="rect-borders mt-20 w-90"
        @click="emitClose"
        >
            {{ $t('order_not_accepted.button') }}
        </LongButton>
    </div>
  </div>
</template>

<script>
import LongButton from "@/components/LongButton";

export default {
  data() {
    return {};
  },
  props: {

  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
  },
  mounted() {
    this.toggleGlobalScrolling(true);
  },
  beforeDestroy() {
    this.toggleGlobalScrolling(false);
  },
  computed: {
    
  },
  components: {
    LongButton,
  },
};
</script>

<style scoped>
p,
h3 {
  margin: 0;
  padding: 0;
}

.mt-20 {
  margin-top: 2.5vh;
}

.w-90 {
  width: 90%;
}

.error-modal {
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  z-index: 11;
  padding: 20px 0 25px;
  position: absolute;
}

img.hero {
  height: 16vh;
  width: auto;
}

.error img {
  height: 70px;
}

p.error-msg {
  color: #908f8d;
}

p {
  padding-top: 10px;
}

h5 {
  color: #a4a3a1;
  text-align: center;
  font-size: 1.7vh;
}

h3 {
  color: #e2223b;
  padding-top: 2.2vh;
  font-size: 2.3vh;
}

.hide-content {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 10;
  overflow: hidden;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.4);
  opacity: 1;
  top: 0;
  left: 0;
}
</style>