/*
    PositionSaver

    Saves menu position to local storage, retrieves it when needed
*/

const LS_TAG = 'position_saver';

import Vue from 'vue'

function save(key, data) {
    window.localStorage.setItem(`${LS_TAG}:${key}`, JSON.stringify(data));
}

function load(key) {
    const item = window.localStorage.getItem(`${LS_TAG}:${key}`);

    if (!item) return null;

    return JSON.parse(item);
}

function apply(saved) {
    Vue.nextTick(() => {
        window.document.documentElement.scrollTop = saved;
    });
}

function clear(key) {
    window.localStorage.removeItem(`${LS_TAG}:${key}`);
}

export default {
    save,
    load,
    apply,
    clear
}